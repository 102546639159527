import styled from 'styled-components'

const Card = styled.div`
  box-sizing: border-box;
  border-radius: 6px;
`

const DarkCard = styled(Card)`
  ${({theme}) => `
  border: 2px solid ${theme.colors.primaryLight};
  background-color: ${theme.colors.primaryDark};
  `}
`

const LightCard = styled(Card)`
  ${({theme}) => `
  padding: 1.2rem 1.2rem 0.6rem;
  background-color:${theme.colors.primaryLight};
  `}
`
const PledgeCard = styled(DarkCard)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  padding: 1.2rem 0;
`
export {LightCard, DarkCard, PledgeCard}
