import styled from 'styled-components'
import {Form as FormikForm} from 'formik'

const PledgeSliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #ffffff;
  border-radius: 6px;
  flex-basis: 345px;
  flex-grow: 1;
  height: 156px;
  padding: 10px;
  h6 {
    width: 100%;
  }

  ${({theme}) => `
      @media (${theme.screenSizes.md}){
        padding: 2rem 1rem;
      }
  `}
`

const PledgeSlider = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .slider-label {
    font-weight: bold;
    color: ${({theme}) => theme.colors.primaryLight};
  }
`

const PledgeDurationContainer = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-right: 1rem;
  }
`

const PledgePhotoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const PledgeAmountLabel = styled.div`
  display: flex;
  align-items: top;
  span {
    font-size: 1.2rem;
    color: #8e8f9e;
    font-weight: 900;
    line-height: 16px;
  }
`

const Form = styled(FormikForm)`
  & > * {
    margin-bottom: 2rem;
  }
  .pledge-message-container {
    margin-bottom: 0;
  }
  ${PledgeSliderContainer} {
    margin-top: 28px;
  }
`

export {
  PledgeSliderContainer,
  PledgeSlider,
  PledgeDurationContainer,
  PledgePhotoContainer,
  PledgeAmountLabel,
  Form,
}
