import {UPDATE_USER} from './types'

const updateUser = (user) => {
  return {
    type: UPDATE_USER,
    payload: user,
  }
}

export {updateUser}
