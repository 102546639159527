import React from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import Button from '@material-ui/core/Button'

import {makeStyles} from '@material-ui/core/styles'
import './animations.css'

const useStyles = makeStyles((theme) => ({
  button: {
    padding: '14px 12px',
    borderRadius: 6,
  },
  primary: {
    backgroundColor: theme.colors.secondaryLight,
    color: theme.colors.primaryLight,
    boxShadow: '0px 2px 8px rgba(202, 0, 61, 0.5)',
  },
  secondary: {
    backgroundColor: theme.colors.secondaryDark,
    color: theme.colors.primaryLight,
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.5)',
  },
  dropdown: {
    color: theme.colors.primaryLight,
    fontSize: '1.2rem',
    lineHeight: '1.4rem',
    padding: 0,
  },
}))

const StyledButton = styled(Button)`
  ${({theme, buttontype}) => `
    :hover {
      background-color: ${theme.buttons.state[`${buttontype}Hover`]}
    }
    :active { 
      background-color: ${theme.buttons.state[`${buttontype}Pressed`]}
    }
    :disable { 
      background-color: ${theme.buttons.state[`${buttontype}Disabled`]}
    }
  `}
`
const DefaultButton = ({
  type = 'primary',
  onClick,
  customStyles = {},
  children,
  ...props
}) => {
  const classes = useStyles()
  return (
    <StyledButton
      buttontype={type}
      classes={{root: 'animated-button'}}
      className={`${classes.button} ${classes[type]}`}
      variant="contained"
      style={customStyles}
      onClick={onClick}
      {...props}>
      {children}
    </StyledButton>
  )
}

const DropdownButton = (
  {handleToggle = () => {}, label = '', propRef, menuID},
  isOpen,
) => {
  const classes = useStyles()

  return (
    <Button
      className={`${classes.button} ${classes.dropdown}`}
      ref={propRef}
      aria-controls={isOpen ? menuID : undefined}
      aria-haspopup="true"
      onClick={handleToggle}>
      {label}
      <img alt="" src="/chevron.svg" />
    </Button>
  )
}

const LinkButton = styled(Link)`
  ${({theme, type}) => `
      font-weight: 900; 
      color: ${theme.colors.textPrimary};
      letter-spacing: 3px;
      text-decoration: none;
      text-transform: uppercase;
      transition:  all 0.2s ease-in-out;;
      position: relative;
      font-size: 1.2rem;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -10px;
        width: 0px;
        height: 5px;
        margin: 5px 0 0;
        transition:  all 0.2s ease-in-out;;
        transition-duration: 0.75s;
        opacity: 0;
        background-color: ${theme.colors.secondaryLight}
      }
      &:focus,
      &:hover {
        cursor: pointer;
          &:after {
            width: 100%;
            opacity: 1;
          }
      }
  
  `}
`

export {DefaultButton, DropdownButton, LinkButton}
