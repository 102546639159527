import React from 'react'
import Typography from '@material-ui/core/Typography'

import {DefaultButton as ActionButton} from '../../components/Buttons'

import {FOUNDING_MEMBERS} from '../../utils/constants'
import {withStyles} from '@material-ui/core/styles'

const StyledText = withStyles((theme) => ({
  h2: {
    textAlign: 'center',
    marginBottom: '2.4rem',
  },
  body1: {
    marginTop: '2.4rem',
    marginBottom: '1.2rem',
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.6rem',
    },
  },
  caption: {
    marginTop: '2.4rem',
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.6rem',
    },
  },
}))(Typography)

const OurMission = ({navigateToPledge}) => {
  return (
    <>
      <StyledText variant="h2" color="primary">
        Our Mission
      </StyledText>

      <StyledText variant="body1" color="primary">
        The100kPledge is a social justice platform committed to the{' '}
        <b>economic empowerment of Black Americans.</b>
        <br /> <br />
      </StyledText>

      <StyledText variant="body1" color="primary">
        The result of centuries of systemic anti-Black racism, the typical Black
        household in America obtains just one-tenth of the wealth of a typical
        white household. This financial imbalance is deeply ingrained and has a
        cascading effect on the health, safety, agency, and prosperity of
        communities of color.
        <br /> <br />
      </StyledText>

      <StyledText variant="body1" color="primary">
        But that doesn’t mean it’s insurmountable.
        <br /> <br />
      </StyledText>

      <StyledText variant="body1" color="primary">
        By making material investments into Black communities that have been
        systematically excluded from economic mobility, we begin to dismantle
        the discriminatory structures that perpetuate this inequality.
        <br /> <br />
      </StyledText>

      <StyledText variant="body1" color="primary">
        We can’t solely rely on politicians or billionaires to fix these
        problems.
        <br /> <br />
      </StyledText>

      <StyledText variant="body1" color="primary">
        Created by business managers, founders, investors, physicians, lawyers,
        consultants, small business owners and freelancers, The100kPledge is a
        web-based social contract where individuals and organizations commit a
        minimum of $100,000 to initiatives advancing economic opportunity for
        Black communities.
        <br /> <br />
      </StyledText>

      <StyledText variant="body1" color="primary">
        Today, The100kPledge serves as a starting point - helping to identify
        tangible actions we can take and formalizing a dedication to specific
        goals. The pledge history serves as a public leger, documenting the
        commitments we make.
        <br /> <br />
      </StyledText>

      <StyledText variant="body1" color="primary">
        As The100kPledge community grows in the coming months, expanded features
        will encourage accountability and track progress towards commitments.
        Additional resources will guide direct actions related to individual
        pledges.
        <br /> <br />
      </StyledText>
      <ActionButton
        customStyles={{width: '100%'}}
        onClick={navigateToPledge}
        type="primary">
        make your pledge
      </ActionButton>
      <StyledText variant="caption" color="primary" component="p">
        Meet the 100+ founding members behind The100kPledge:
      </StyledText>
      {FOUNDING_MEMBERS.map((member) => (
        <StyledText variant="body1" color="primary">
          {member}
        </StyledText>
      ))}
    </>
  )
}

export default OurMission
