import styled from 'styled-components'

export const PageLayout = styled.div`
  ${({theme}) => `
    position: relative;
    padding: 3rem 1.5rem 0;
    min-height: 100vh;
    max-width: 1440px;
    margin: 0 auto;
    background-color: ${theme.colors.primaryDark};
    @media (${theme.screenSizes.xs}) { 
      padding: 3rem 9% 0;
     };
    @media (${theme.screenSizes.sm}) { 
      padding: 3rem 15% 0;
     };
    @media (${theme.screenSizes.md}) { 
      padding: 3rem 22% 0;
     };
    @media (${theme.screenSizes.lg}) { 
      padding: 3rem 32rem 0;
     };
  `}
`
