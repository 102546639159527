import React from 'react'
import styled from 'styled-components'
import Slider from '@material-ui/core/Slider'
import {withStyles} from '@material-ui/core/styles'


const followersMarks = [
  {
    value: 0,
    scaledValue: 100000,
    label: "100k"
  },
  {
    value: 10,
    scaledValue: 200000,
    label: "200k"
  },
  {
    value: 20,
    scaledValue: 500000,
    label: "500k"
  },
  {
    value: 30,
    scaledValue: 1000000,
    label: "1M"
  },
  {
    value: 40,
    scaledValue: 10000000,
    label: "10M"
  },
  {
    value: 50,
    scaledValue: 50000000,
    label: "50M"
  },
  {
    value: 60,
    scaledValue: 100000000,
    label: "100M"
  }
];

const scale = value => {
  const scaleinc = 10
  const previousMarkIndex = Math.floor(value / scaleinc);
  const previousMark = followersMarks[previousMarkIndex];
  const remainder = value % scaleinc;
  if (remainder === 0) {
    return previousMark.scaledValue;
  }
  const nextMark = followersMarks[previousMarkIndex + 1];
  const increment = (nextMark.scaledValue - previousMark.scaledValue) / scaleinc;
  return remainder * increment + previousMark.scaledValue;
};

function numFormatter(num) {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(0) + "M"; // convert to M for number from > 1 million
  } else if (num < 900) {
    return num; // if value < 1000, nothing to do
  }
}

const PledgeSlider = withStyles((theme) => ({
  root: {
    color: theme.colors.secondaryLight,
    height: 6,
    width: '100%',
  },
  thumb: {
    height: 30,
    width: 30,
    backgroundColor: theme.colors.secondaryLight,
    border: '2px solid currentColor',
    marginTop: -12,
    marginLeft: -15,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
}))(Slider)
const SliderContainer = styled.div`
  display: flex;
  flex-basis: 19.3rem;
  flex-grow: 1;
  padding: 0 2rem;
`
// Slider has been an unsupported aspect of utilizing Material-UI with Formik. This was suggested fix
// by material-ui until support by either packages comes in
// more information can be found here https://github.com/stackworx/formik-material-ui/issues/121#

export const fieldToSlider = ({
  field,
  form: {isSubmitting},
  disabled = false,
  ...props
}) => {
  return {
    disabled: isSubmitting || disabled,
    ...props,
    ...field,
    name: field.name,
    value: field.value,
  }
}
const FormikSlider = ({min = 0, max = 0, step = 0, ...props}) => {
  const {
    field: {name},
    form: {setFieldValue},
  } = props

  const [value, setValue] = React.useState(1);

  const handleSliderChange = (e, value) => {
    setFieldValue(name, scale(value))
    setValue(value);
  }

  return (
    <SliderContainer>
      <PledgeSlider
        {...fieldToSlider(props)}
        onChange={handleSliderChange}
        aria-label="pledge contribution slide"
        value={value}
        min={0}
        step={1}
        max={60}
        valueLabelFormat={numFormatter}
        marks={followersMarks}
        scale={scale}

      />
    </SliderContainer>
  )
}
export default FormikSlider
