import React, {useState} from 'react'
import {Switch, Route} from 'react-router-dom'
import {useHistory} from 'react-router-dom'
import {PageLayout} from './components/Layouts'
import {useAuth0} from '@auth0/auth0-react'

// import NavigationDrawer from './components/NavigationDrawer'
import Home from './containers/Home'
import OurMission from './containers/OurMission'
import MakePledge from './containers/MakePledge'
import Dialog from './containers/Dialog'
import PledgeDetails from './containers/PledgeDetails'

const App = () => {
  const [open, setOpen] = useState(false)
  const {isAuthenticated} = useAuth0()
  const history = useHistory()

  const navigateToPledge = () => {
    isAuthenticated ? history.push('/make-a-pledge') : setOpen(true)
  }
  return (
    <PageLayout>
      {/* Mobile Navigation drawer - feature for v2 */}
      {/* <NavigationDrawer /> */}
      <Switch>
        <Route
          path="/our-mission"
          render={() => <OurMission navigateToPledge={navigateToPledge} />}
        />
        <Route
          path="/make-a-pledge"
          render={() => <MakePledge navigateToPledge={navigateToPledge} />}
        />
        <Route 
          exact path="/pledge/:id" 
          render={(props) => <PledgeDetails {...props} /> } 
        />
        <Route
          path="/"
          render={() => <Home navigateToPledge={navigateToPledge} />}
        />
        

      </Switch>
      <Dialog
        navigateToPledge={navigateToPledge}
        open={open}
        toggleDialog={setOpen}
      />
    </PageLayout>
  )
}

export default App
