const PLEDGE_MESSAGE_MAP = {
  Hire: {
    description:
      'full-time, part-time, contract work, or internship / fellowship career opportunities for Black talent',
    category: 'career opportunities',
  },
  Invest: {
    description:
      'investment into Black-owned businesses and venture funds',
    category: 'investments',
  },
  Donate: {
    description:
      'financial support to scholarships for Black students / organizations advancing economic opportunity, or in-kind services for Black-owned businesses, talent, and entrepreneurs',
    category: 'donations',
  },
}

const SORT_OPTIONS = [
  {label: 'Most Recent', value: 'timeStamp'},
  {label: 'Amount Pledged', value: 'pledgeAmount'},
  {label: 'Most Liked', value: 'likeCounter'},
  {label: 'My Pledge', value: 'email'},
]

const FOUNDING_MEMBERS = [
  'Aaron Holiday, 645 ventures',
  'Adrian Gazzoli, TribalScale',
  'Alice Mao, TribalScale',
  'Amy Doyle, Manpower Group',
  'Andrew Yang, Presidential Candidate',
  'Anjula Acharia, Trinity Ventures',
  'Anne Sophie Hurst, VSC',
  'Anu Duggal, Female Founders Fund',
  'Archana Chattha, VSC',
  'Ashish Kaushal, Consciously Unbiased',
  'Ben Arnon, Color Farm Media',
  'Bijan Sabet, Spark Capital',
  'Brandon Bryant, Harlem Capital',
  'Brandon Proctor, Absolute Financial Management',
  'Caitlin MacGregor, Plum',
  'Callum Leneman, VSC',
  'Charles Hudson, Precursor VC',
  'Cheryl Campos, Republic',
  'Chirag Chotalia, Threshold VC',
  'Christina Lewis, All Star Code',
  'Christine Spagnuolo, Sorelle Group',
  "Damali Taylor, O'Melveny",
  'Dan Rosen, Commerce Ventures',
  'Darnell Smith, Mojo',
  'Denmark West, Connectivity Ventures',
  'Dulé Hill, ',
  'Ebru Sonmezisik, TribalScale',
  'Elaine Zelby , Signalfire',
  'Eric Gonzalez, VSC',
  'Erika Alexander, Color Farm Media',
  'Erin Bury, Willful',
  'Eve Blossom, Creative Destruction Lab',
  'Farhan Thawar, Shopify',
  'Frank Mong, Helium',
  'Frederik Groce, BLCK VC',
  'Genil Washington, Stanford Health Care',
  'George Revutsky, Postmates',
  'Glynn Washington, Snap Judgement',
  'Grant McDougall, BlueOcean.ai',
  'Hari Menon, Paanini',
  'Heather Gadonniex, Samasource',
  'Henri Pierre-Jacques, Harlem Capital',
  'Hooman Radfar, Expa',
  'Hunter Walk, homebrew',
  'Ido Leffler, ',
  'Ila Afsharipour, KeyBanc Capital Markets',
  'Iman Abuzeid, Incredible Health',
  'James Allen, ',
  'Javaughn Lawrence, Eniac Ventures',
  'Jeff Rosenthal, Summit Series',
  'Kai Bond, CourtsideVC',
  'Kamesh Nagarajan, Morgan Stanley',
  'Kane Sarhan, The Well',
  'Karan Wadhera, Casa Verde Capital',
  'Katie Stanton, Moxxie Ventures',
  'Kevin Hawkins, Booking Holdings',
  'Kevin Oulds, Willful',
  'Kojo Dufu, Atlas Merchant Capital',
  'Kunal Modi, Obama Foundation, McKinsey',
  'Laine Conklin, Conklin Communications',
  'Leire Polo Martin, TribalScale',
  'Lendale Johnson, The Johnson High Performance Tennis Academy',
  'Leo Giel, YouAppi',
  'Lisa Oshima, Socialize Mobilize',
  'Liza Nebel, BlueOcean.ai',
  'Madison Corr, VSC',
  'Maggie Philbin, VSC',
  'Manan Mehta, Unshackled',
  'Manesh Patel, Szul',
  'Mark Suster, upfront',
  'Marta Bulaich, Ridge Ventures',
  'Martha van Berkel, Schema',
  'Matt Frischer, Wristcam',
  'Matt Gunnin, Esports One',
  'Michael Skolnik, The Soze Agency',
  'Mike Katz, mParticle',
  'Mohit Veoli, TD Bank',
  'Nadine Nana, Freelance',
  'Nanea Reeves, TRIPP',
  'Neera Chanani, Chanani Group',
  'Neha Bhatia, OpenInvest',
  'Nihal Mehta, Eniac Ventures',
  'Nikhil Basu Trivedi, ',
  'Nithin Rao, Autonomic',
  'Nnamdi Okike, 645 ventures',
  'Paige Craig, Outlander Labs',
  'Paras Shah, Different Strokes',
  'Paul Williams, Mondelēz International',
  'Prem Kumta, Flavor Group',
  'Raj Amin, Teem Ventures',
  'Raj Kapoor, Lyft',
  'Reshma Saujani, Girls Who Code',
  'Rick Marini, Grindr',
  'Rob Oshima, Rally',
  'Sara Ali, Google',
  'Satya Patel, homebrew',
  'Sean Green, Arternal',
  'Shafin Jadavji, CubeCom',
  'Shai Goldman, Silicon Valley Bank',
  'Shala Burroughs, Radar',
  'Shawn Merani, Parade Ventures',
  'Sheetal Jaitly, TribalScale',
  'Sid Shah, BCG Digital Ventures',
  'Sri Ambati, H2O.ai',
  'Stephanie Lampkin, Blendoor',
  'Suresh Bhat, Vouchr',
  'Tina Sharkey, ',
  'Tom Chavez, Super{set}',
  'Trevor Martin, Mammoth',
  'Ty Stiklorius, Friends At Work',
  'Vijay Chattha, VSC',
  'Wendy Gonzalez , Samasource',
]
export {PLEDGE_MESSAGE_MAP, SORT_OPTIONS, FOUNDING_MEMBERS}
