import React, {useReducer} from 'react'
import styled from 'styled-components'
import {LinkedinShareButton, FacebookShareButton} from 'react-share'
import {connect} from 'react-redux'
import debounce from 'lodash.debounce'
import {Link} from 'react-router-dom'

import {Typography} from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import {withStyles} from '@material-ui/core/styles'

import TwitterIcon from '@material-ui/icons/Twitter'
import FavoriteIcon from '@material-ui/icons/Favorite'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import FacebookIcon from '@material-ui/icons/Facebook'

import {LightCard} from '../../components/Cards'
import SocialMediaIcon from '../../components/SocialMedia'

import {composeSocialMediaMessage, formatCurrency} from '../../utils'
import {likePledge} from '../../redux/actions'

const PledgerDetails = styled.div`
  span {
    font-size: 1rem;
  }
`
const Container = styled(LightCard)`
  margin: 16px 0;
`

const PledgeFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`

const TwitterShare = styled.a`
  display: inline-block;
`

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  .MuiAvatar-root {
    margin-right: 1rem;
  }
`

const Layout = styled.div`
  display: flex;
  align-items: center;
`
const StyledText = withStyles((theme) => ({
  colorTextSecondary: {
    fontSize: '1rem',
    marginTop: '2px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.2rem',
    },
  },
  body2: {
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.6rem',
    },
  },
}))(Typography)

function reducer(state, action) {
  switch (action.type) {
    case 'increment':
      return {likeCounter: state.likeCounter + 1, liked: true}
    case 'decrement':
      return {likeCounter: state.likeCounter - 1, liked: false}
    default:
      throw new Error()
  }
}

const PledgeCard = ({
  hasSocialMedia = false,
  pledge = {},
  userInfo = {},
  dispatchLikePledge = () => {},
}) => {
  const {
    name,
    title,
    id,
    pledgeMessage,
    pledgeAmount,
    picture = '',
    likeCounter,
  } = pledge
  const formattedCurrency = formatCurrency(pledgeAmount, true)
  const [favorite, dispatch] = useReducer(reducer, {likeCounter, liked: false})

  const onFailHandler = () => dispatch({type: 'decrement'})

  const toggleLike = () => {
    favorite.liked
      ? dispatch({type: 'decrement'})
      : dispatch({type: 'increment'})
    dispatchLikePledge(pledge, !favorite.liked, onFailHandler)
  }
  return (
    <Container>
      <CardHeader>
        <Avatar alt={name} src={picture} />
        <div>
          <Link to={'/pledge/' + id}>
            <Typography variant="h4" color="secondary" >
              {name}
            </Typography>
          </Link>
          <PledgerDetails>
            <StyledText variant="subtitle2" color="textSecondary">
              {title}
            </StyledText>
          </PledgerDetails>
        </div>
      </CardHeader>

      <StyledText variant="body2">{pledgeMessage}</StyledText>
      <PledgeFooter>
        <StyledText variant="subtitle1" color="secondary">
          {formattedCurrency}
        </StyledText>
        <Layout
          style={{visibility: `${hasSocialMedia ? 'visibile' : 'hidden'}`}}>
          <Layout>
            {favorite.liked && (
              <StyledText variant="subtitle1" color="secondary">
                {favorite.likeCounter}
              </StyledText>
            )}
            <SocialMediaIcon
              color={favorite.liked ? 'secondary' : 'default'}
              onClick={debounce(toggleLike, 300)}>
              <FavoriteIcon />
            </SocialMediaIcon>
          </Layout>
          <FacebookShareButton
            url={`${process.env.REACT_APP_100K_DOMAIN}/pledge/${pledge.id}`}
            quote={`${composeSocialMediaMessage(
              pledge,
              userInfo,
              false
            )}`}
            >
            <SocialMediaIcon>
              <FacebookIcon />
            </SocialMediaIcon>
          </FacebookShareButton>
          <TwitterShare
            href={`https://twitter.com/intent/tweet?text=${composeSocialMediaMessage(
              pledge,
              userInfo,
              true
            )}`}
            rel="me">
            <SocialMediaIcon disableRipple>
              <TwitterIcon />
            </SocialMediaIcon>
          </TwitterShare>
          <LinkedinShareButton
            url={`${process.env.REACT_APP_100K_DOMAIN}/pledge/${pledge.id}`}
            itemProp={LinkedInIcon}>
            <SocialMediaIcon>
              <LinkedInIcon />
            </SocialMediaIcon>
          </LinkedinShareButton>
        </Layout>
      </PledgeFooter>
    </Container>
  )
}
const mapStateToProps = ({user: {userInfo}}) => {
  return {
    userInfo,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatchLikePledge: (pledge, likeStatus, onFail) => {
      dispatch(likePledge(pledge, likeStatus, onFail))
    },
  }
}
const ConnectedPledgeCard = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PledgeCard)
export default ConnectedPledgeCard
