const sendEmail = async (
  data = {email: '', name: '', message: '', amount: '', time: '', title: ''},
) => {
  const response = await fetch(process.env.REACT_APP_EMAIL_ENDPOINT, {
    method: 'POST',
    mode: 'no-cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data),
  })

  response.text().then(function (text) {
    return text || {}
  })
}

export default sendEmail
