export const ADD_PLEDGE = 'ADD_PLEDGE'
export const ADD_PLEDGE_SUCCESS = 'ADD_PLEDGE_SUCCESS'
export const ADD_PLEDGE_FAIL = 'ADD_PLEDGE_FAIL'
export const GET_PLEDGES = 'GET_PLEDGES'
export const GET_PLEDGES_SUCCESS = 'GET_PLEDGES_SUCCESS'
export const UPDATE_USER = 'UPDATE_USER'
export const LIKE_PLEDGE = 'LIKE_PLEDGE'
export const LIKE_PLEDGE_SUCCESS = 'LIKE_PLEDGE_SUCCESS'
export const LIKE_PLEDGE_FAIL = 'LIKE_PLEDGE_FAIL'
export const SORT_PLEDGE = 'SORT_PLEDGE'
export const GET_USER_PLEDGE = 'GET_USER_PLEDGE'
export const UPDATE_PLEDGES = 'UPDATE_PLEDGES'
export const UPDATE_SORT_FILTER = 'UPDATE_SORT_FILTER'
