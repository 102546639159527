import React, {useEffect , useState }  from 'react'
import {useHistory, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'

import isEmpty from 'lodash.isempty'
import PledgeCard from './PledgeCard'
import {getPledges, getUserPledge, likePledge, updateUser} from '../redux/actions'

import {DefaultButton as ActionButton} from '../components/Buttons'
import styled from 'styled-components'

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2.4rem;

  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index:100;
  padding:5px 0;
  background-color:#0F101A;

  ${({theme}) => `
    button {
      flex-grow: 1;
  `}
`

const PledgeDetails = ({
  match,
  pledges,
  dispatchGetPledges,
  ...props
}) => {
  
useEffect(() => {
  isEmpty(pledges) && dispatchGetPledges()
}, [dispatchGetPledges, pledges])

const history = useHistory()
const navigateHome = () => history.push('/')

return (
  
    <div>
        {pledges.map(info => {
        return ( info.id == match.params.id ?
          <PledgeCard key={info.id} pledge={info} hasSocialMedia />
          :
          false  
        )
        })}

       
        <ButtonGroup>
          <ActionButton 
          onClick={navigateHome}
          Type="primary">
              View All Pledges
          </ActionButton>
        </ButtonGroup>
        
     
    </div>
  )
}

const mapStateToProps = ({
  user: {userInfo},
  pledges: {pledges,  pledgesLoaded},
}) => {
  return {
    userInfo,
    pledges,
    pledgesLoaded
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchGetPledges: () => {
      dispatch(getPledges())
    },
    dispatchUpdateUser: (user) => {
      dispatch(updateUser(user))
    },
    dispatchLikePledge: () => {
      dispatch(likePledge())
    },
  }
}
const ConnectedPledgeDetails = connect(mapStateToProps, mapDispatchToProps)(PledgeDetails)
export default ConnectedPledgeDetails
