import {UPDATE_USER} from '../actions/types'

function pledgeDataReducer(state = {}, action = {}) {
  switch (action.type) {
    case UPDATE_USER:
      return Object.assign({}, state, {
        userInfo: action.payload,
        isAuthenticated: true,
      })
    default:
      return state
  }
}

export default pledgeDataReducer
