import React from 'react'
import {withStyles, makeStyles} from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    backgroundColor: theme.colors.inputBackground,
  },
  label: {
    color: theme.colors.primaryLight,
    fontWeight: 900,
    fontSize: '1.4rem',
  },
  description: {
    color: theme.colors.primaryLight,
  },
}))

const StyleCheckBox = withStyles((theme) => ({
  root: {
    color: theme.colors.secondaryLight,
    '&$checked': {
      color: theme.colors.secondaryLight,
    },
  },
  checked: {},
}))((props) => <Checkbox color="default" {...props} />)

const formatSentenceCase = (sentence) =>
  sentence.replace(sentence[0], sentence[0].toUpperCase())

const CheckBox = ({label, description, arrayHelpers, values}) => {
  const classes = useStyles()
  return (
    <>
      <FormControlLabel
        classes={{label: classes.label}}
        control={
          <StyleCheckBox
            checked={values.pledgeType.includes(label)}
            onChange={(e) => {
              if (e.target.checked) {
                arrayHelpers.push(label)
              } else {
                const idx = values.pledgeType.indexOf(label)
                arrayHelpers.remove(idx)
              }
            }}
          />
        }
        label={label}
      />
      <Typography color="primary" variant="body2">
        {formatSentenceCase(description)}
      </Typography>
    </>
  )
}

export default CheckBox
