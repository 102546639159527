import React, { useState } from "react";

import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

import globalStyles from '../../styles/globalStyles'
import headshotData from '../../utils/images/headshotData'
import {DefaultButton as ActionButton} from '../../components/Buttons'

const Wrapper = styled.div`
  text-align:center;
  margin-bottom:50px;
`

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom:10px;
`

const ImageContainer = styled.div`
${({theme}) => `
  flex-basis: 33.3333%;
  position: relative;
  @media (${theme.screenSizes.md}) { 
    flex-basis: 25%;
  };
  @media (${theme.screenSizes.lg}) { 
    flex-basis: 20%;
  }
`}
`
const Image = styled.img`
  height: 100%;
  width: 100%; 
`

const ImageLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const FounderName =styled(Typography)`
${({theme}) => `
  font-weight: bold;
  text-align: center;
  @media (${theme.screenSizes.md}) { 
    font-size: 1.4rem;
  };
`}
`

const FounderCompany =styled(Typography)`
${({theme}) => `
  font-size: 1.1rem;
  text-align: center;
  @media (${theme.screenSizes.md}) { 
    font-size: 1.2rem;
  };
`}
`

const ImageBlock = ({ data: { name = "", company = "", img = "" } = {} }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  return(
      <ImageContainer 
        className={`${!isImageLoaded ? "waiting" : "loaded"}`}
      >
        <Image 
          src={img} 
          alt={name}
          onLoad={() => setTimeout(() => setIsImageLoaded(true), 0)}
          />
        <ImageLayer>
          <FounderName variant='body2' color="primary">
            {name}
          </FounderName>
          <FounderCompany variant='body2' color="primary">
            {company}
          </FounderCompany>
        </ImageLayer>
      </ImageContainer>
  )
}

class Headshots extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      founders : headshotData,    
      // showing all 
      itemsToDisplay : headshotData.length,
      showFull:true,
    };
    this.showMore = this.showMore.bind(this);
  }

  showMore() {
    let flength = this.state.founders.length;
    this.setState({itemsToDisplay:flength,showFull:true});
  }
  render() {
    return (
      <Wrapper>
        <Container>
          {this.state.founders.slice(0,this.state.itemsToDisplay).map((data, index) => <ImageBlock key={index} data={data}/> )} 
        </Container>
        { !this.state.showFull ? 
        <ActionButton type="secondary" onClick={this.showMore}>
            <img alt="" src="/chevron.svg" />&nbsp;show more founders
        </ActionButton>
        : null }
      </Wrapper>
    )
  }
}

export default Headshots
