import React from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'

const Button = styled(IconButton)`
  padding: 6px;
`

const SocialMediaIcon = ({children, ...props}) => {
  return (
    <Button disableFocusRipple {...props}>
      {children}
    </Button>
  )
}

export default SocialMediaIcon
