import React, {useState} from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import {Switch, Route} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import {useAuth0} from '@auth0/auth0-react'

import PledgeForm from './PledgeForm/PledgeForm'
import PledgeSuccess from './PledgeSuccess'
import Logo from '../components/Logo'

import './screen-animation.css'

const PledgeDescription = styled.div`
  margin-bottom: 2rem;
`
const MakePledgeContainer = () => {
  const [inProp, setInProp] = useState(false)
  const {isAuthenticated} = useAuth0()

  return (
    <>
      <Switch>
        <Route path="/make-a-pledge/confirmation" component={PledgeSuccess} />
        <Route
          path="/make-a-pledge"
          render={() => (
            <MakePledgePage
              isAuthenticated={isAuthenticated}
              showLoading={setInProp}
            />
          )}
        />
      </Switch>

      {/* TODO: Set removeLoading to when database-write returns successful */}
      <CSSTransition in={inProp} timeout={3000} classNames="loading-screen">
        <div />
      </CSSTransition>
    </>
  )
}

const MakePledgePage = ({isAuthenticated, ...props}) => (
  <>
    <Logo />
    <PledgeDescription>
      <Typography variant="body1" color="primary">
        Use the form below to outline your commitment to economic empowerment.
      </Typography>
      <br />
      {!isAuthenticated ? (
        <Typography variant="body1" color="primary">
          Our team will review your submission and you will be notified via
          email when your pledge has been added to the 100kPledge permanent
          public record.
        </Typography>
      ) : (
        ''
      )}
      <br />
      <Typography variant="body1" color="primary">
        Share your pledge on social media, post actions you're taking to meet
        your goal, and invite your colleagues to join - because we’re all in
        this together.
      </Typography>
    </PledgeDescription>
    <PledgeForm {...props} />
  </>
)

export default MakePledgeContainer
