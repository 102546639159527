import React from 'react'
import styled from 'styled-components'
import {connect} from 'react-redux'
import {useAuth0} from '@auth0/auth0-react'

import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import {makeStyles} from '@material-ui/core/styles'

import {DropdownButton} from '../components/Buttons'
import {sortPledge, getUserPledge} from '../redux/actions'
const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 3rem 0;
  label {
    margin-right: 8px;
  }
`
const FilterLabel = styled.label`
  ${({theme}) => `
        color: ${theme.colors.secondaryLight};
        font-weight: 900;
        line-height: 1.4rem;
        font-size: 1.2rem;
        letter-spacing: 0.3rem;
    `}
`

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  menu: {
    backgroundColor: theme.colors.primaryDark,
    color: theme.colors.primaryLight,
  },
}))

const PledgeFilter = ({
  filter = [],
  user: {
    userInfo: {email = ''},
  },
  dispatchSortPledge = () => {},
  dispatchGetUserPledge = () => {},
}) => {
  const classes = useStyles()

  // TODO: Refactor & rearchitecture how we want the flow to work without anchorRef
  const [open, setOpen] = React.useState(false)
  const [filterOption, setFilterOption] = React.useState(filter[0])
  const anchorRef = React.useRef(null)
  const {loginWithRedirect, isAuthenticated} = useAuth0()

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }
  const filterPledges = (event, sortOption) => {
    if (sortOption.value === 'email') {
      isAuthenticated ? dispatchGetUserPledge(email) : authenticateUser()
    } else {
      dispatchSortPledge(sortOption.value)
    }
    setFilterOption(sortOption)
    setOpen(false)
  }

  const authenticateUser = () => {
    loginWithRedirect({redirect_uri: window.location.origin})
  }
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  const MENU_ID = '"menu-list-grow"'
  return (
    <>
      <FilterContainer>
        <FilterLabel>SORT BY: </FilterLabel>
        <DropdownButton
          isOpen={open}
          propRef={anchorRef}
          label={filterOption.label}
          menuID={MENU_ID}
          handleToggle={handleToggle}></DropdownButton>
      </FilterContainer>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal>
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'left top' : 'center bottom',
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id={MENU_ID}
                  onKeyDown={handleListKeyDown}
                  className={classes.menu}>
                  {filter.map((option) => (
                    <MenuItem
                      key={option.label}
                      onClick={(e) => filterPledges(e, option)}>
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatchSortPledge: (sortOption) => {
      dispatch(sortPledge(sortOption))
    },
    dispatchGetUserPledge: (email) => {
      dispatch(getUserPledge(email))
    },
  }
}

const mapStateToProps = ({user}) => {
  return {
    user,
  }
}

const ConnectedPledgeFilter = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PledgeFilter)
export default ConnectedPledgeFilter
