import {put, takeEvery} from 'redux-saga/effects'

import {
  ADD_PLEDGE,
  ADD_PLEDGE_SUCCESS,
  ADD_PLEDGE_FAIL,
  GET_PLEDGES,
  GET_PLEDGES_SUCCESS,
  LIKE_PLEDGE,
  LIKE_PLEDGE_SUCCESS,
  LIKE_PLEDGE_FAIL,
  UPDATE_PLEDGES,
} from '../actions/types'

import db from '../../db'
const collection = process.env.REACT_APP_DATABASE_COLLECTION

export function* addPledge({pledge, onSuccess, onFailure}) {
  try {
    const ref = db.collection("my_collection").doc();
    const newId = ref.id;
    yield db.collection(collection).doc(newId).set(pledge)
    pledge.id = newId
    yield put({type: ADD_PLEDGE_SUCCESS, pledge})
    yield put({type: GET_PLEDGES})
    setTimeout(onSuccess, 2000)
  } catch (error) {
    yield put({type: ADD_PLEDGE_FAIL, error})
    onFailure(false)
  }
}

export function* getPledges() {
  
  const dbdata = yield db
    .collection(collection)
    .where('status', '==', 'APPROVED')
    .get()
  const pledges = dbdata.docs.map((doc) => {
    const data = doc.data()
    const id = doc.id
    return {...data, id}
  })

  yield put({type: GET_PLEDGES_SUCCESS, pledges})
}


export function* likePledge({pledge, likeStatus, onFail}) {
  try {
    const {likeCounter, id} = pledge
    const newCount = likeStatus ? likeCounter + 1 : likeCounter - 1
    yield db.collection(collection).doc(id).update({
      likeCounter: newCount,
    })
    yield put({type: LIKE_PLEDGE_SUCCESS})
  } catch (error) {
    console.log(error)
    onFail()
    yield put({type: LIKE_PLEDGE_FAIL, error})
  }
}

export function* updatePledges() {
  const dbdata = yield db
    .collection(collection)
    .where('status', '==', 'APPROVED')
    .get()
  const pledges = dbdata.docs.map((doc) => {
    const data = doc.data()
    const id = doc.id
    return {...data, id}
  })

  yield put({type: UPDATE_PLEDGES, pledges})
}

export function* watchPledge() {
  yield takeEvery(ADD_PLEDGE, addPledge)
  yield takeEvery(GET_PLEDGES, getPledges)
  yield takeEvery(LIKE_PLEDGE, likePledge)
  yield takeEvery(LIKE_PLEDGE_SUCCESS, updatePledges)  // Do we need to update pledges?
}

export default watchPledge
