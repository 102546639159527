import React from 'react'
import styled from 'styled-components'

const Logo = styled.img`
  display: block;
  margin: 2rem auto 2rem auto;
  width: 50%;
`
const StyledLogo = () => {
  return <a href="/"><Logo alt="100k pledge logo" src="/logo.svg" /></a>
}

export default StyledLogo
