module.exports = {
  fonts: {
    primary: 'Lato',
    secondary: 'ArchivalBlack',
  },
  colors: {
    backgroundColor: '#0F101A',
    inputBackground: '#272831',
    primaryLight: '#FEFEFE',
    primaryDark: '#0F101A',
    secondaryLight: '#FF195F',
    secondaryDark: '#495460',
    error: '#F0474D ',
    success: '#0ACD7A',
    textPrimary: '#8E8F9E',
    textSecondary: '#74757E',
  },
  buttons: {
    state: {
      primaryPressed: '#F2024B',
      primaryDisabled: '#FF195F',
      primaryHover: '#FF2F6E',
      secondaryPressed: '#424D58',
      secondaryDisabled: '#495460',
      secondaryHover: '#576371',
    },
  },
  screenSizes: {
    xs: 'min-width: 425px',
    sm: 'min-width: 768px',
    md: 'min-width: 1024px',
    lg: 'min-width: 1440px',
  },
}
