import React from 'react'

import MuiTextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import {withStyles} from '@material-ui/core/styles'

import FormikError from '../Error'

const StyledTextField = withStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      backgroundColor: theme.colors.inputBackground,
      color: theme.colors.primaryLight,
      borderRadius: 4,
    },
    '& .MuiOutlinedInput-root': {
      '& input + fieldset': {
        border: '1px solid #74757E',
      },

      '& input:disabled + fieldset': {
        borderColor: theme.colors.backgroundColor,
        borderWidth: 0,
        backgroundColor: 'transparent',
      },
      '&:focused input + fieldset': {
        border: `3px solid ${theme.colors.textSecondary}`,
      },
      '&.Mui-focused input + fieldset': {
        border: `3px solid ${theme.colors.primaryLight}`,
      },
    },
    '& .Mui-disabled': {
      backgroundColor: theme.colors.backgroundColor,
    },
  },
}))(MuiTextField)

const TextField = ({label, id, classes = {}, ...props}) => {
  const {
    field: {value, name},
    field,
    form: {touched, initialStatus, errors},
  } = props

  const isTextFieldPrepopulated = name in initialStatus

  const onChange = (event) => {
    const {
      field: {name},
      form: {handleChange, setFieldTouched, touched},
    } = props

    if (!touched[name]) {
      setFieldTouched(name, true)
    }
    handleChange(event)
  }

  return (
    <div>
      <InputLabel style={{marginBottom: 10}} htmlFor={id}>
        {label}
      </InputLabel>
      <StyledTextField
        {...field}
        error={errors[name] && touched[name]}
        onChange={onChange}
        className={classes}
        InputProps={{disabled: false}}
        colors="primary"
        value={value}
        id={id}
        variant="outlined"
      />
      <FormikError name={name} />
    </div>
  )
}

export default TextField
