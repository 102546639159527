import ArchivalBlack from './fonts/Archivo_Black/ArchivoBlack-Regular.ttf'
import LatoRegular from './fonts/Lato/Lato-Regular.ttf'
import LatoBold from './fonts/Lato/Lato-Bold.ttf'
import LatoBlack from './fonts/Lato/Lato-Black.ttf'
import LatoBoldItalic from './fonts/Lato/Lato-BoldItalic.ttf'

export const archivalBlack = {
  fontFamily: 'ArchivalBlack',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('ArchivalBlack-Regular'),
    url(${ArchivalBlack}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

export const lato = {
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    local('Lato-Regular'),
    url(${LatoRegular}) format('truetype')
  `,
}

export const latoBold = {
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: 600,
  src: `
    local('Lato-Bold'),
    url(${LatoBold}) format('truetype')
  `,
}
export const latoBlack = {
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: 900,
  src: `
    local('Lato-Black'),
    url(${LatoBlack}) format('truetype')
  `,
}

export const latoBoldItalic = {
  fontFamily: 'Lato',
  fontStyle: 'italic',
  fontWeight: 600,
  src: `
    local('/Lato-BoldItalic'),
    url(${LatoBoldItalic}) format('truetype')
  `,
}
