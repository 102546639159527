import React from 'react'
import {useHistory} from 'react-router-dom'
import {useAuth0} from '@auth0/auth0-react'

import {makeStyles} from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'

import {DefaultButton as ActionButton} from '../../components/Buttons'

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.colors.inputBackground,
    padding: '2rem 2.4rem',
    textAlign: 'center',
    maxWidth: '47rem',
    height: '20rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

const DialogContainer = ({navigateToPledge, open, toggleDialog}) => {
  const classes = useStyles()
  const {loginWithRedirect} = useAuth0()
  const history = useHistory()

  const handleClose = (value) => {
    toggleDialog(false)
  }

  const navigationToPledge = () => {
    history.push('/make-a-pledge')
    handleClose()
  }
  return (
    <Dialog
      classes={{paper: classes.paper}}
      onClose={handleClose}
      aria-labelledby="dialog-label"
      open={open}>
      <Typography id="dialog-label" variant="body1" color="primary">
        We use LinkedIn to verify your identity and approve your pledge.
      </Typography>
      <ActionButton
        onClick={() =>
          loginWithRedirect({
            redirectUri: `${window.location.origin}/make-a-pledge`,
          })
        }
        type="primary"
        customStyles={{
          backgroundColor: '#2D72AD',
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.5)',
        }}>
        Continue with linkedin
      </ActionButton>
      <ActionButton type="secondary" onClick={navigationToPledge}>
        skip this step
      </ActionButton>
    </Dialog>
  )
}

export default DialogContainer
