import React, {useEffect, useState} from 'react'
import {Formik, Field, FieldArray} from 'formik'
import {useHistory} from 'react-router-dom'
import {useAuth0} from '@auth0/auth0-react'
import {connect} from 'react-redux'
import get from 'lodash.get'
import pickBy from 'lodash.pickby'
import * as Yup from 'yup'
import Obfuscate from 'react-obfuscate'

import {makeStyles} from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel'
import IconButton from '@material-ui/core/IconButton'
import PhotoCamera from '@material-ui/icons/PhotoCamera'
import FormGroup from '@material-ui/core/FormGroup'

import TextField from '../../components/TextField'
import Slider from '../../components/Slider'
import CheckBox from '../../components/CheckBox'
import {DefaultButton as ActionButton} from '../../components/Buttons'
import FormikError from '../../components/Error'
import FormikErrorFocus from 'formik-error-focus'

import {
  PledgeSliderContainer,
  PledgeSlider,
  PledgePhotoContainer,
  PledgeAmountLabel,
  Form,
} from './PledgeForm.styles'

import {PLEDGE_MESSAGE_MAP} from '../../utils/constants'
import {formatCurrency, composePledgeMessage} from '../../utils'
import {addPledge, updateUser} from '../../redux/actions'
import storage from '../../storage'
import sendEmail from '../../utils/email'

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100%',
    border: '0',
  },
  selectField: {
    width: '100%',
    borderRadius: 6,
    height: 'max-content',
    backgroundColor: theme.colors.inputBackground,
  },
  avatar: {
    height: 88,
    width: 88,
    marginTop: 5,
  },
  label: {
    color: theme.colors.primaryLight,
    fontWeight: 900,
    fontSize: '1.4rem',
  },
  description: {
    color: theme.colors.primaryLight,
  },
}))

const PledgeSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field cannot be left empty'),
  title: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field cannot be left empty'),
  pledgeType: Yup.array().required('Please select one of the options'),
  email: Yup.string()
    .email('Invalid email')
    .required('This field cannot be left empty'),
})

const PledgeForm = ({
  showLoading,
  dispatchUpdateUser = () => {},
  userInfo = {},
  dispatchAddPledge,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const {user, isAuthenticated} = useAuth0()
  const [formikInitialValue, setInitialValue] = useState({
    name: '',
    title: '',
    email: '',
    picture: '',
    pledgeAmount: 100000,
    pledgeMessage: '',
    pledgeType: [],
    likeCounter: 0,
    status: 'PENDING',
  })

  useEffect(() => {
    isAuthenticated && dispatchUpdateUser(user)
  }, [dispatchUpdateUser, isAuthenticated, user])

  useEffect(() => {
    isAuthenticated &&
      setInitialValue({
        name: get(userInfo, 'name', ''),
        title: get(userInfo, 'title', ''),
        email: get(userInfo, 'email', ''),
        picture: get(userInfo, 'picture', ''),
        pledgeAmount: 100000,
        pledgeType: [],
        pledgeMessage: '',
        likeCounter: 0,
        status: 'APPROVED',
      })
  }, [isAuthenticated, userInfo])


  const PLEDGE_TYPES = Object.keys(PLEDGE_MESSAGE_MAP)

  const uploadProfilePicture = (file) => {
    var storageRef = storage.ref(`images/${file.name}`)
    return storageRef.put(file).then(function (snapshot) {
      return snapshot.ref.getDownloadURL().then(function (downloadURL) {
        return downloadURL
      })
    })
  }
  const setInitialStatus = (userInfo) => {
    const linkedInInfo = pickBy(userInfo, (info) => !!info)
    return linkedInInfo
  }
  const onSuccess = (setSubmitting, history, pledge) => () => {
    setSubmitting(false)
    document.querySelector('.animate-expand-button').classList.remove('active')

    sendEmail({
      email: get(pledge, 'email', ''),
      name: get(pledge, 'name', ''),
      amount: get(pledge, 'pledgeAmount', ''),
      message: get(pledge, 'pledgeMessage', ''),
      title: get(pledge, 'title', ''),
      time: '10 years',
    }).then((data) => {
      console.log('Email sent successfully')
    })

    history.push('/make-a-pledge/confirmation')
    showLoading(false)
  }
  const onFailure = (setSubmitting) => () => {
    setSubmitting(false)
    document.querySelector('.animate-expand-button').classList.remove('active')
  }

  const submitForm = (values, {setSubmitting}) => {
    showLoading(true)
    document.querySelector('.animate-expand-button').classList.add('active')

    const pledgeMessage = composePledgeMessage(values)
    const timeStamp = new Date()
    const pledge = {...values, pledgeMessage, timeStamp}
    dispatchAddPledge(
      pledge,
      onSuccess(setSubmitting, history, pledge),
      onFailure(setSubmitting),
    )
  }
  return (
    <Formik
      enableReinitialize={true}
      initialValues={formikInitialValue}
      initialStatus={setInitialStatus(userInfo)}
      validationSchema={PledgeSchema}
      onSubmit={submitForm}>
      {({values, handleSubmit, isSubmitting, setFieldValue}) => {
        const formattedCurrency = formatCurrency(values.pledgeAmount, true)
        return (
          <Form>
            <PledgePhotoContainer>
              <Typography variant="subtitle2" color="primary">
                Your Photo
              </Typography>
              {Object.keys(userInfo).length ? (
                <Avatar
                  alt="photo"
                  src={userInfo.picture}
                  className={classes.avatar}
                />
              ) : (
                <>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="icon-button-file"
                    type="file"
                    style={{display: 'none'}}
                    onChange={async (event) => {
                      const profilePic = await uploadProfilePicture(
                        event.currentTarget.files[0],
                      )
                      setFieldValue('picture', profilePic)
                    }}
                  />
                  <Avatar
                    alt="photo"
                    className={classes.avatar}
                    src={values.picture}>
                    <label htmlFor="icon-button-file">
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span">
                        <PhotoCamera />
                      </IconButton>
                    </label>
                  </Avatar>
                </>
              )}
            </PledgePhotoContainer>
            <Field
              type="text"
              name="name"
              component={TextField}
              label="Full Name"
              id="full-name"
              classes={classes.textField}
            />
            <Field
              type="text"
              name="email"
              component={TextField}
              label="Email"
              id="email"
              classes={classes.textField}
            />

            <Field
              type="text"
              name="title"
              component={TextField}
              label="Company"
              placeholder="Sample Inc."
              id="title-role"
              classes={classes.textField}
            />
            <PledgeSliderContainer>
              <Typography variant="subtitle2" color="primary">
                Over the next 10 years, I will dedicate
              </Typography>
              <PledgeAmountLabel>
                <Typography variant="h3" color="primary">
                  {formattedCurrency}
                </Typography>
                <span>USD</span>
              </PledgeAmountLabel>
              <PledgeSlider>
                
                <Field
                  type="number"
                  name="pledgeAmount"
                  component={Slider}
                />
                
              </PledgeSlider>
            </PledgeSliderContainer>
            <div>
              <InputLabel style={{marginBottom: 5}} htmlFor="pledgeType">
                To the following:
              </InputLabel>

              <FieldArray
                id="pledgeType"
                type="checkbox"
                name="pledgeType"
                render={(arrayHelpers) => {
                  return (
                    <>
                      <FormGroup>
                        {PLEDGE_TYPES.map((option, i) => (
                          <CheckBox
                            key={i}
                            label={option}
                            description={PLEDGE_MESSAGE_MAP[option].description}
                            values={values}
                            arrayHelpers={arrayHelpers}
                          />
                        ))}
                      </FormGroup>
                      <FormikError name="pledgeType" />
                    </>
                  )
                }}
              />
            </div>
            <ActionButton
              classes={{root: 'animate-expand-button'}}
              customStyles={{width: '100%', marginTop: '2rem'}}
              onClick={handleSubmit}
              type="primary">
              submit
            </ActionButton>
            <FormikErrorFocus
              // See scroll-to-element for configuration options: https://www.npmjs.com/package/scroll-to-element
              offset={-40}
              align={'top'}
              focusDelay={200}
              ease={'outQuad'}
              duration={300}
            />
            <Typography variant="body2" color="primary" align="center">
              *Once approved, your pledge will appear on the 100kPledge public ledger. 
              <br/>
              For any questions, reach out to <Obfuscate email="info@the100kpledge.com"/>.
              <br/><br/>
            </Typography>
          </Form>
        )
      }}
    </Formik>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchUpdateUser: (user) => {
      dispatch(updateUser(user))
    },
    dispatchAddPledge: (form, onSuccess, onFailure) => {
      dispatch(addPledge(form, onSuccess, onFailure))
    },
  }
}

const mapStateToProps = ({user: {userInfo}, pledge}) => {
  return {
    userInfo,
    pledge,
  }
}

const ConnectedPledgeForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PledgeForm)
export default ConnectedPledgeForm
