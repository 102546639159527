import {
  ADD_PLEDGE,
  GET_PLEDGES,
  LIKE_PLEDGE,
  SORT_PLEDGE,
  GET_USER_PLEDGE,
} from './types'

const addPledge = (pledge, onSuccess, onFailure) => {
  return {
    type: ADD_PLEDGE,
    pledge,
    onSuccess,
    onFailure,
  }
}

const getPledges = () => {
  return {
    type: GET_PLEDGES,
  }
}

const likePledge = (pledge, likeStatus, onFail) => {
  return {
    type: LIKE_PLEDGE,
    pledge,
    likeStatus,
    onFail,
  }
}
const sortPledge = (sortType) => {
  return {
    type: SORT_PLEDGE,
    sortType,
  }
}
const getUserPledge = (email) => {
  return {
    type: GET_USER_PLEDGE,
    email,
  }
}
export {addPledge, getPledges, likePledge, sortPledge, getUserPledge}
