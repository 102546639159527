import React from 'react'
import {ErrorMessage} from 'formik'
import styled from 'styled-components'

const Error = styled.div`
  color: ${({theme}) => theme.colors.error};
  padding:2px 0;
  display: flex;
  align-items: center;
  :before {
    content: ' ';
    display: inline-block;
    background-image: url(/icon-error.svg);
    background-size: auto;
    height: 18px;
    width: 18px;
    background-position: center;
  }
`

const FormikError = ({name}) => (
  <ErrorMessage name={name}>{(msg) => <Error>{msg}</Error>}</ErrorMessage>
)

export default FormikError
