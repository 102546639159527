import React from 'react'
import ReactDOM from 'react-dom'
import {Auth0Provider} from '@auth0/auth0-react'
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { wrapHistory } from "oaf-react-router";

import {Provider} from 'react-redux'

import {ThemeProvider} from 'styled-components'
import WebFont from 'webfontloader'
import {
  ThemeProvider as MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'

import Firebase, {FirebaseContext} from './firebase'
import store from './redux/store'
import * as serviceWorker from './serviceWorker'

import './index.css'
import './styles/fonts/fonts.css'
import globalStyles from './styles/globalStyles'
import {lato} from './styles/fonts'

import App from './App'

// Font loaders for self-hosted font-families. Required for Material-UI
WebFont.load({
  custom: {
    families: [
      `${globalStyles.fonts.primary}:n4,i4,n9`,
      globalStyles.fonts.secondary,
    ],
  },
})

// Material UI config object for maintaining customization of fonts across material-ui Typography components
// this references the globalStyles Object to maintain a consistent theme structure throughout the app
// between styled-components & material-ui
const typography = {
  typography: {
    htmlFontSize: 10,
    fontFamily: [globalStyles.fonts.primary],
    h2: {
      fontSize: '4rem',
      fontFamily: globalStyles.fonts.secondary,
      lineHeight: '4.4rem',
      textTransform: 'uppercase',
    },
    h3: {
      fontSize: '4rem',
      fontFamily: globalStyles.fonts.secondary,
      lineHeight: '4.4rem',
    },
    h4: {
      fontSize: '1.5rem',
      textTransform: 'uppercase',
      fontFamily: globalStyles.fonts.secondary,
    },
    subtitle1: {
      fontSize: '1.3rem',
      textTransform: 'uppercase',
      fontFamily: globalStyles.fonts.secondary,
      lineHeight: '1.5rem',
      letterSpacing: '0.03rem',
    },
    subtitle2: {
      fontSize: '1.3rem',
      fontFamily: globalStyles.fonts.primary,
      fontWeight: 900,
      letterSpacing: '1px',
    },
    body1: {
      fontSize: '1.5rem',
      letterSpacing: '0.03rem',
      color:globalStyles.colors.primaryLight,
      '& a': {
        color:globalStyles.colors.primaryLight,
      }
    },
    body2: {
      fontSize: '1.3rem',
      fontFamily: globalStyles.fonts.primary,
      lineHeight: '150%',
      letterSpacing: '0.03rem',
      '& a': {
        color:globalStyles.colors.primaryLight,
      }
    },
    caption: {
      fontSize: '1.4rem',
      fontFamily: globalStyles.fonts.primary,
      fontWeight: 900,
      letterSpacing: '0.03rem',
    },
    button: {
      fontSize: '1.3rem',
      fontWeight: 900,
      letterSpacing: 3,
      textTransform: 'uppercase',
    },
  },
}

// Material UI config object for maintaining customization theme across material-ui components
// this references the globalStyles Object to maintain a consistent theme structure throughout the app
// between styled-components & material-ui
const palette = {
  palette: {
    primary: {
      main: globalStyles.colors.primaryLight,
    },
    secondary: {
      light: globalStyles.colors.textSecondary,
      main: globalStyles.colors.secondaryLight,
      dark: globalStyles.colors.textPrimary,
    },
    text: {
      primary: globalStyles.colors.textPrimary,
      secondary: globalStyles.colors.textSecondary,
    },
  },
}

const breakpoints = {
  values: {
    xs: globalStyles.screenSizes.xs,
    sm: globalStyles.screenSizes.sm,
    md: globalStyles.screenSizes.md,
    lg: globalStyles.screenSizes.lg,
  },
}

const overRides = {
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [lato],
        body: {
          backgroundColor: globalStyles.colors.primaryDark,
          fontWeight: 'normal',
          fontSize: '1.2rem',
        },
      },
    },
    MuiInputBase: {
      input: {
        fontSize: '1.6rem',
        '&:-webkit-autofill': {
          '-webkit-box-shadow': `0 0 0 30px ${globalStyles.colors.inputBackground} inset !important`,
          '-webkit-text-fill-color': `${globalStyles.colors.primaryLight} !important`,
        },
      },
    },
    MuiTypography: {
      root: {
        color: globalStyles.colors.primaryDark,
      },
    },
  },
}
const theme = createMuiTheme({
  ...globalStyles,
  ...typography,
  ...palette,
  ...breakpoints,
  ...overRides,
})

// TODO: Refactor this, recommended implementation from Auth0
const history = createBrowserHistory()
const onRedirectCallback = (appState) => {
  // If using a Hash Router, you need to use window.history.replaceState to
  // remove the `code` and `state` query parameters from the callback url.
  // window.history.replaceState({}, document.title, window.location.pathname);
  history.replace((appState && appState.returnTo) || window.location.pathname)
}
wrapHistory(history);

ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <Router history={history}>
            <Auth0Provider
              domain={process.env.REACT_APP_AUTHO_DOMAIN}
              clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
              redirectUri={`${window.location.origin}`}
              audience={process.env.REACT_APP_AUTHO_AUDIENCE_URL}
              onRedirectCallback={onRedirectCallback}>
              <FirebaseContext.Provider value={Firebase}>
                <App />
              </FirebaseContext.Provider>
            </Auth0Provider>
          </Router>
        </Provider>
      </ThemeProvider>
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
