import AndrewYang from './headshot-images/000_Andrew-Yang.png'
import WendyGonzalez from './headshot-images/001_Wendy-Gonzalez.png'
import VijayChattha from './headshot-images/002_Vijay-Chattha-Headshot.png'
import StephenDeBerry from './headshot-images/003_Stephen-DeBerry.png'
import SheetalJaitly from './headshot-images/004_Sheetal-Jaitly.png'
import ReshmaSaujani from './headshot-images/005_Reshma-Saujani.png'
import PaulWilliams from './headshot-images/006_Paul-Williams.png'
import ParasShah from './headshot-images/007_Paras-Shah.png'
import NihalMehta from './headshot-images/008_Nihal-Mehta.png'
import MelissaTidwell from './headshot-images/009_Melissa-Tidwell.png'
import MarkSuster from './headshot-images/010_Mark-Suster.png'
import JaniceChen from './headshot-images/011_Janice-Chen.png'
import ImanAbuzeid from './headshot-images/012_Iman-Abuzeid.png'
import GlynnWashington from './headshot-images/013_Glynn-Washington.png'
import EverettHarper from './headshot-images/014_Everett-Harper.png'
import ErinGore from './headshot-images/015_Erin-Gore.png'
import ChrisFarmer from './headshot-images/016_Chris-Farmer.png'
import CharlesHudson from './headshot-images/017_Charles-Hudson.png'
import ArchanaChattha from './headshot-images/019_Archana-Chattha.png'
import HenriPierreJacques from './headshot-images/020_Henri Pierre-Jacques.png'
import AaronHoliday from './headshot-images/021_Aaron-Holiday.png'
import AdrianGazzoli from './headshot-images/022_Adrian-Gazzoli.png'
import AlexAlpert from './headshot-images/023_Alex-Alpert.png'
import AliceMao from './headshot-images/024_Alice-Mao.jpg'
import AmyDoyle from './headshot-images/025_Amy-Doyle.png'
import AnneSophieHurst from './headshot-images/026_Anne-Sophie-Hurst.png'
import AnuDuggal from './headshot-images/027_Anu-Duggal.png'
import AshishKaushal from './headshot-images/029_Ashish-Kaushal.jpg'
import BenArnon from './headshot-images/030_Ben-Arnon.png'
import BijanSabet from './headshot-images/031_Bijan-Sabet.png'
import CaitlinMacGregor from './headshot-images/032_Caitlin-MacGregor.png'
import CallumLeneman from './headshot-images/033_Callum-Leneman.png'
import CherylCampos from './headshot-images/034_Cheryl-Campos.png'
import ChristineSpagnuolo from './headshot-images/035_Christine-Spagnuolo.png'
import DianaGoodwin from './headshot-images/036_Diana-Goodwin.png'
import EbruSonmezisik from './headshot-images/037_Ebru-Sonmezisik.png'
import ElaineZelby from './headshot-images/038_Elaine-Zelby.png'
import EricGonzalez from './headshot-images/039_Eric-Gonzalez.png'
import EricWard from './headshot-images/040_Eric-Ward.jpg'
import ErikaAlexander from './headshot-images/041_Erika-Alexander.png'
import ErinBury from './headshot-images/042_Erin-Bury.png'
import FarhanThawar from './headshot-images/043_Farhan-Thawar.png'
import FrankMong from './headshot-images/044_Frank-Mong.png'
import FrederikGroce from './headshot-images/045_Frederik-Groce.png'
import GeorgeRevutsky from './headshot-images/046_George-Revutsky.png'
import GrantMcDougall from './headshot-images/047_Grant-McDougall.png'
import HeatherGadonniex from './headshot-images/048_Heather-Gadonniex.png'
import HoomanRadfar from './headshot-images/049_Hooman-Radfar.png'
import HunterWalk from './headshot-images/050_Hunter-Walk.png'
import IdoLeffler from './headshot-images/051_Ido-Leffler.png'
import JeffRosenthal from './headshot-images/052_Jeff-Rosenthal.png'
import JimScheinman from './headshot-images/053_Jim-Scheinman.png'
import JoshConstine from './headshot-images/054_Josh-Constine.png'
import KaneSarhan from './headshot-images/055_Kane-Sarhan.png'
import KanyiMaqubela from './headshot-images/056_Kanyi-Maqubela.png'
import KaranWadhera from './headshot-images/057_Karan-Wadhera.png'
import KatieStanton from './headshot-images/058_Katie-Stanton.png'
import KevinOulds from './headshot-images/059_Kevin-Oulds.png'
import KojoDufu from './headshot-images/060_Kojo-Dufu.png'
import LaineConklin from './headshot-images/061_Laine-Conklin.png'
import LeirePoloMartin from './headshot-images/062_Leire-Polo-Martin.png'
import LendaleJohnson from './headshot-images/063_Lendale-Johnson.png'
import LeoGiel from './headshot-images/064_Leo-Giel.png'
import LisaOshima from './headshot-images/065_Lisa-Oshima-(Whelan).png'
import LizaNebel from './headshot-images/066_Liza-Nebel.png'
import MadisonCorr from './headshot-images/067_Madison-Corr.png'
import MaggiePhilbin from './headshot-images/068_Maggie-Philbin.png'
import MananMehta from './headshot-images/069_Manan-Mehta.png'
import MarthavanBerkel from './headshot-images/070_Martha-van-Berkel.png'
import MattFrischer from './headshot-images/071_Matt-Frischer.png'
import MattGunnin from './headshot-images/072_Matt-Gunnin.png'
import MichaelSkolnik from './headshot-images/073_Michael-Skolnik.png'
import MohitVeoli from './headshot-images/074_Mohit-Veoli.png'
import NadineNana from './headshot-images/075_Nadine-Nana.png'
import NaneaReeves from './headshot-images/076_Nanea-Reeves.png'
import NehaBhatia from './headshot-images/077_Neha-Bhatia.png'
import NikhilBasuTrivedi from './headshot-images/078_Nikhil-Basu-Trivedi.png'
import NithinRao from './headshot-images/079_Nithin-Rao.png'
import PardonMakumbe from './headshot-images/080_Pardon-Makumbe.png'
import PeteFlint from './headshot-images/081_Pete-Flint.png'
import PremKumta from './headshot-images/082_Prem-Kumta.png'
import RajKapoor from './headshot-images/083_Raj-Kapoor.png'
import SaraAli from './headshot-images/084_Sara-Ali.png'
import SaraDeshpande from './headshot-images/085_Sara-Deshpande.png'
import SatishPillai from './headshot-images/086_Satish-Pillai.png'
import SatyaPatel from './headshot-images/087_Satya-Patel.png'
import ShafinJadavji from './headshot-images/088_Shafin-Jadavji.png'
import ShalaBurroughs from './headshot-images/089_Shala-Burroughs.png'
import ShawnMerani from './headshot-images/090_Shawn-Merani.png'
import SriAmbati from './headshot-images/091_Sri-Ambati.png'
import StephanieLampkin from './headshot-images/092_Stephanie-Lampkin.png'
import TabreezVerjee from './headshot-images/093_Tabreez-Verjee.png'
import TimChang from './headshot-images/094_Tim-Chang.png'
import TinaSharkey from './headshot-images/095_Tina-Sharkey.png'
import TrevorMartin from './headshot-images/096_Trevor-Martin.png'
import AnnaNitschke from './headshot-images/097_Anna-Nitschke.png'
import HadleyHarris from './headshot-images/098_Hadley-Harris.png'
import KamilSaeid from './headshot-images/099_Kamil-Saeid.png'
import KristinMcDonald from './headshot-images/100_Kristin-McDonald.png'
import TimYoung from './headshot-images/101_Tim-Young.png'
import VicSingh from './headshot-images/102_Vic-Singh.png'
import AlexShah from './headshot-images/103_Alex-Shah.png'
import AnjulaAcharia from './headshot-images/104_Anjula-Acharia.png'
import BharatVasan from './headshot-images/105_Bharat-Vasan.png'
import ChantalKreviazuk from './headshot-images/106_Chantal-Kreviazuk.png'
import ChiragChotalia from './headshot-images/107_Chirag-Chotalia.png'
import ChristinaLewis from './headshot-images/108_Christina-Lewis.png'
import DamaliTaylor from './headshot-images/109_Damali-Taylor.png'
import DanRosen from './headshot-images/110_Dan-Rosen.png'
import DarnellSmith from './headshot-images/111_Darnell-Smith.png'
import DenmarkWest from './headshot-images/112_Denmark-West.png'
import DuleHill from './headshot-images/113_Dule-Hill.png'
import EveBlossom from './headshot-images/114_Eve-Blossom.png'
import GenilWashington from './headshot-images/115_Genil-Washington.png'
import HariMenon from './headshot-images/116_Hari-Menon.png'
import IlaAfsharipour from './headshot-images/117_Ila-Afsharipour.png'
import JavaughnLawrence from './headshot-images/118_Javaughn-Lawrence.png'
import KaiBond from './headshot-images/119_Kai-Bond.png'
import KameshNagarajan from './headshot-images/120_Kamesh-Nagarajan.png'
import KevinHawkins from './headshot-images/121_Kevin-Hawkins.png'
import KunalModi from './headshot-images/122_Kunal-Modi.png'
import ManeshPatel from './headshot-images/123_Manesh-Patel.png'
// import MarkSuster from './headshot-images/124_Mark-Suster.png' Duplicate
import MartaBulaich from './headshot-images/125_Marta-Bulaich.png'
import MichaelKatz from './headshot-images/126_Michael-Katz.png'
import NeeraChanani from './headshot-images/127_Neera-Chanani.png'
import NnamdiOkike from './headshot-images/128_Nnamdi-Okike.png'
import PaigeCraig from './headshot-images/129_Paige-Craig.png'
import RaineMaida from './headshot-images/130_Raine-Maida.png'
import RajAmin from './headshot-images/131_Raj-Amin.png'
import RickMarini from './headshot-images/132_Rick-Marini.png'
import RobOshima from './headshot-images/133_Rob-Oshima.png'
import SeanGreen from './headshot-images/134_Sean-Green.png'
import ShaiGoldman from './headshot-images/135_Shai-Goldman.png'
import SidShah from './headshot-images/136_Sid-Shah.png'
import SureshBhat from './headshot-images/137_Suresh-Bhat.png'
import TomChavez from './headshot-images/138_Tom-Chavez.png'
import TyStiklorius from './headshot-images/139_Ty-Stiklorius.jpg'
import KaylaAbbassi from './headshot-images/140_Kayla-Abbassi.png'
import MattUffalussy from './headshot-images/141_Matt-Uffalussy.png'


const headshotData = [
    {
        "name": "Andrew Yang",
        "company": "Humanity Forward",
        "role": "Presidential Candidate",
        "img": `${AndrewYang}`
    },
    {
        "name": "Wendy Gonzalez",
        "company": "Samasource",
        "role": "CEO",
        "img": `${WendyGonzalez}`
    },
    {
        "name": "Vijay Chattha",
        "company": "VSC",
        "role": "Founder & CEO",
		"img": `${VijayChattha}`
  	},
	{
		"name": "Stephen DeBerry",
		"company": "Bronze Investments",
		"role": "Founder & Managing Partner",
		"img": `${StephenDeBerry}`
  	},
	{
		"name": "Sheetal Jaitly",
		"company": "TribalScale",
		"role": "Founder & CEO",
		"img": `${SheetalJaitly}`
  	},
	{
		"name": "Reshma Saujani",
		"company": "Girls Who Code",
        "role": "Founder & CEO",
		"img": `${ReshmaSaujani}`
  	},
	{
		"name": "Paul Williams",
		"company": "Mondelēz International",
		"role": "Director Global Design Ingredients",
		"img": `${PaulWilliams}`
  	},
	{
		"name": "Paras Shah",
		"company": "Different Strokes",
		"role": "Strategy Director",
		"img": `${ParasShah}`
  	},
	{
		"name": "Nihal Mehta",
		"company": "Eniac Ventures",
		"role": "Founding General Partner",
		"img": `${NihalMehta}`
  	},
	{
		"name": "Melissa Tidwell",
		"company": "Ex-Reddit/Google",
		"role": "Startup Advisor",
		"img": `${MelissaTidwell}`
  	},
	{
		"name": "Mark Suster",
        "company": "Upfront Ventures",
		"role": "Managing Partner",
		"img": `${MarkSuster}`
  	},
	{
		"name": "Janice Chen",
		"company": "Mammoth",
		"role": "Co-founder & CTO",
		"img": `${JaniceChen}`
  	},
	{
		"name": "Iman Abuzeid",
		"company": "Incredible Health",
		"role": "Co-founder & CEO",
		"img": `${ImanAbuzeid}`
  	},
	{
		"name": "Glynn Washington",
		"company": "Snap Judgement",
		"role": "Executive Producer / Host",
		"img": `${GlynnWashington}`
  	},
	{
		"name": "Everett Harper",
		"company": "Truss",
		"role": "CEO",
		"img": `${EverettHarper}`
  	},
	{
		"name": "Erin Gore",
		"company": "Garden Society",
		"role": "Founder & CEO",
		"img": `${ErinGore}`
  	},
	{
		"name": "Chris Farmer",
		"company": "SignalFire",
		"role": "Founder & CEO",
		"img": `${ChrisFarmer}`
  	},
	{
		"name": "Charles Hudson",
		"company": "Precursor VC",
		"role": "Managing Partner",
		"img": `${CharlesHudson}`
  	},
	{
		"name": "Archana Chattha",
		"company": "VSC",
		"role": "Partner",
		"img": `${ArchanaChattha}`
  	},
	{
		"name": "Henri Pierre-Jacques",
		"company": "Harlem Capital",
		"role": "Managing Partner",
		"img": `${HenriPierreJacques}`
  	},
	{
		"name": "Aaron Holiday",
		"company": "645 ventures",
		"role": "Co-founder & Managing Partner",
		"img": `${AaronHoliday}`
  	},
	{
		"name": "Adrian Gazzoli",
		"company": "Tribal Scale",
		"role": "Product Manager",
		"img": `${AdrianGazzoli}`
  	},
	{
		"name": "Alex Alpert",
		"company": "Shyft ",
		"role": "Founder & CEO",
		"img": `${AlexAlpert}`
  	},
	{
		"name": "Alice Mao",
		"company": "Tribal Scale",
		"role": "Engineer",
		"img": `${AliceMao}`
  	},
	{
		"name": "Amy Doyle",
		"company": "ManpowerGroup",
		"role": "Senior Vice President",
		"img": `${AmyDoyle}`
  	},
	{
		"name": "Anne Sophie Hurst",
		"company": "VSC",
		"role": "Head of People",
		"img": `${AnneSophieHurst}`
  	},
	{
		"name": "Anu Duggal",
		"company": "Female Founders Fund",
		"role": "Partner",
		"img": `${AnuDuggal}`
  	},
	{
		"name": "Ashish Kaushal",
		"company": "Consciously Unbiased",
		"role": "Co-founder",
		"img": `${AshishKaushal}`
  	},
	{
		"name": "Ben Arnon",
		"company": "Color Farm Media",
		"role": "Co-founder & CEO",
		"img": `${BenArnon}`
  	},
	{
		"name": "Bijan Sabet",
		"company": "Spark Capital",
		"role": "General Partner",
		"img": `${BijanSabet}`
  	},
	{
		"name": "Caitlin MacGregor",
		"company": "Plum",
		"role": "Co-founder & CEO",
		"img": `${CaitlinMacGregor}`
  	},
	{
		"name": "Callum Leneman",
		"company": "VSC",
		"role": "Visual Communications Consultant",
		"img": `${CallumLeneman}`
  	},
	{
		"name": "Cheryl Campos",
		"company": "Republic",
		"role": "Director of Growth & Partnerships",
		"img": `${CherylCampos}`
  	},
	{
		"name": "Christine Spagnuolo",
		"company": "Sorelle Group",
		"role": "Principal",
		"img": `${ChristineSpagnuolo}`
  	},
	{
		"name": "Diana Goodwin",
		"company": "AquaMobile Swim School",
		"role": "Founder & CEO",
		"img": `${DianaGoodwin}`
  	},
	{
		"name": "Ebru Sonmezisik",
		"company": "Tribal Scale",
		"role": "Senior Product Designer",
		"img": `${EbruSonmezisik}`
  	},
	{
		"name": "Elaine Zelby",
		"company": "SignalFire",
		"role": "Principal & Director of Growth",
		"img": `${ElaineZelby}`
  	},
	{
		"name": "Eric Gonzalez",
		"company": "VSC",
		"role": "General Manager",
		"img": `${EricGonzalez}`
  	},
	{
		"name": "Eric Ward",
		"company": "Western States Center",
		"role": "Executive Director",
		"img": `${EricWard}`
  	},
	{
		"name": "Erika Alexander",
		"company": "Color Farm Media",
		"role": "Producer",
		"img": `${ErikaAlexander}`
  	},
	{
		"name": "Erin Bury",
		"company": "Willful",
		"role": "CEO",
		"img": `${ErinBury}`
  	},
	{
		"name": "Farhan Thawar",
		"company": "Shopify",
		"role": "VP of Engineering",
		"img": `${FarhanThawar}`
  	},
	{
		"name": "Frank Mong",
		"company": "Helium",
		"role": "COO",
		"img": `${FrankMong}`
  	},
	{
		"name": "Frederik Groce",
		"company": "BLCK VC",
		"role": "Co-founder & Board Member",
		"img": `${FrederikGroce}`
  	},
	{
		"name": "George Revutsky",
		"company": "TopMarketer",
		"role": "Vice President of Growth",
		"img": `${GeorgeRevutsky}`
  	},
	{
		"name": "Grant McDougall",
		"company": "BlueOcean.ai",
		"role": "Co-founder",
		"img": `${GrantMcDougall}`
  	},
	{
		"name": "Heather Gadonniex",
		"company": "Samasource",
		"role": "VP Marketing",
		"img": `${HeatherGadonniex}`
  	},
	{
		"name": "Hooman Radfar",
		"company": "Expa",
		"role": "Partner",
		"img": `${HoomanRadfar}`
  	},
	{
		"name": "Hunter Walk",
		"company": "homebrew",
		"role": "Partner",
		"img": `${HunterWalk}`
  	},
	{
		"name": "Ido Leffler",
		"company": "Yoobi",
		"role": "Co-Founder & CEO",
		"img": `${IdoLeffler}`
  	},
	{
		"name": "Jeff Rosenthal",
		"company": "Summit Series",
		"role": "Co-founder",
		"img": `${JeffRosenthal}`
  	},
	{
		"name": "Jim Scheinman",
		"company": "Maven Ventures",
		"role": "Founding Managing Partner",
		"img": `${JimScheinman}`
  	},
	{
		"name": "Josh Constine",
		"company": "SignalFire",
		"role": "Principal Investor & Head of Content",
		"img": `${JoshConstine}`
  	},
	{
		"name": "Kane Sarhan",
		"company": "The Well",
		"role": "Co-founder",
		"img": `${KaneSarhan}`
  	},
	{
		"name": "Kanyi Maqubela",
		"company": "Kindred Ventures",
		"role": "General Partner",
		"img": `${KanyiMaqubela}`
  	},
	{
		"name": "Karan Wadhera",
		"company": "Casa Verde Capital",
		"role": "Managing Partner",
		"img": `${KaranWadhera}`
  	},
	{
		"name": "Katie Stanton",
		"company": "Moxxie Ventures",
		"role": "Founder & General Partner",
		"img": `${KatieStanton}`
  	},
	{
		"name": "Kevin Oulds",
		"company": "Willful",
		"role": "Co-founder & Head of Business",
		"img": `${KevinOulds}`
  	},
	{
		"name": "Kojo Dufu",
		"company": "Atlas Mara",
		"role": "Investor Relations",
		"img": `${KojoDufu}`
  	},
	{
		"name": "Laine Conklin",
		"company": "Conklin Communications",
		"role": "Principal",
		"img": `${LaineConklin}`
  	},
	{
		"name": "Leire Polo Martin",
		"company": "Tribal Scale",
		"role": "Engineering Manager",
		"img": `${LeirePoloMartin}`
  	},
	{
		"name": "Lendale Johnson",
		"company": "The Johnson High Performance Tennis Academy",
		"role": "Founder",
		"img": `${LendaleJohnson}`
  	},
	{
		"name": "Leo Giel",
		"company": "YouAppi",
		"role": "CRO",
		"img": `${LeoGiel}`
  	},
	{
		"name": "Lisa Oshima",
		"company": "Socialize Mobilize",
		"role": "Founder & Managing Consultant",
		"img": `${LisaOshima}`
  	},
	{
		"name": "Liza Nebel",
		"company": "BlueOcean.ai",
		"role": "Co-founder",
		"img": `${LizaNebel}`
  	},
	{
		"name": "Madison Corr",
		"company": "VSC",
		"role": "Account Executive",
		"img": `${MadisonCorr}`
  	},
	{
		"name": "Maggie Philbin",
		"company": "VSC",
		"role": "Vice President",
		"img": `${MaggiePhilbin}`
  	},
	{
		"name": "Manan Mehta",
		"company": "Unshackled",
		"role": "Founding Partner",
		"img": `${MananMehta}`
  	},
	{
		"name": "Martha van Berkel",
		"company": "Schema",
		"role": "CEO & Co-founder",
		"img": `${MarthavanBerkel}`
  	},
	{
		"name": "Matt Frischer",
		"company": "Wristcam",
		"role": "Co-founder & CMO",
		"img": `${MattFrischer}`
  	},
	{
		"name": "Matt Gunnin",
		"company": "Esports One",
		"role": "Founder & CEO",
		"img": `${MattGunnin}`
  	},
	{
		"name": "Michael Skolnik",
		"company": "The Soze Agency",
		"role": "Founding Partner",
		"img": `${MichaelSkolnik}`
  	},
	{
		"name": "Mohit Veoli",
		"company": "TD Bank",
		"role": "Vice President, Everyday Banking Products",
		"img": `${MohitVeoli}`
  	},
	{
		"name": "Nadine Nana",
		"company": "Freelance",
		"role": "Marketing Consultant",
		"img": `${NadineNana}`
  	},
	{
		"name": "Nanea Reeves",
		"company": "TRIPP",
		"role": "Co-founder & CEO",
		"img": `${NaneaReeves}`
  	},
	{
		"name": "Neha Bhatia",
		"company": "OpenInvest",
		"role": "VP of Sales",
		"img": `${NehaBhatia}`
  	},
	{
		"name": "Nikhil Basu Trivedi",
		"company": "",
		"role": "Investor",
		"img": `${NikhilBasuTrivedi}`
  	},
	{
		"name": "Nithin Rao",
		"company": "Autonomic",
		"role": "Co-founder & VP Product",
		"img": `${NithinRao}`
  	},
	{
		"name": "Pardon Makumbe",
		"company": "CRE Venture Capital",
		"role": "Co-founder & Managing Partner",
		"img": `${PardonMakumbe}`
  	},
	{
		"name": "Pete Flint",
		"company": "NFX",
		"role": "Managing Partner",
		"img": `${PeteFlint}`
  	},
	{
		"name": "Prem Kumta",
		"company": "Flavor Group",
		"role": "Founding Partner",
		"img": `${PremKumta}`
  	},
	{
		"name": "Raj Kapoor",
		"company": "Lyft",
		"role": "CSO",
		"img": `${RajKapoor}`
  	},
	{
		"name": "Sara Ali",
		"company": "Google",
		"role": "Corporate Development",
		"img": `${SaraAli}`
  	},
	{
		"name": "Sara Deshpande",
		"company": "Maven Ventures",
		"role": "Partner",
		"img": `${SaraDeshpande}`
  	},
	{
		"name": "Satish Pillai",
		"company": "UCSF Vitalant Research Institute",
		"role": "Senior Investigator of Viral Pathogenesis",
		"img": `${SatishPillai}`
  	},
	{
		"name": "Satya Patel",
		"company": "homebrew",
		"role": "Partner",
		"img": `${SatyaPatel}`
  	},
	{
		"name": "Shafin Jadavji",
		"company": "CubeCom",
		"role": "President & Broker of Record",
		"img": `${ShafinJadavji}`
  	},
	{
		"name": "Shala Burroughs",
		"company": "Wild Turn",
		"role": "VP Strategy",
		"img": `${ShalaBurroughs}`
  	},
	{
		"name": "Shawn Merani",
		"company": "Parade Ventures",
		"role": "Founder & Managing Partner",
		"img": `${ShawnMerani}`
  	},
	{
		"name": "Sri Ambati",
		"company": "H2O.ai",
		"role": "Co-founder & CEO",
		"img": `${SriAmbati}`
  	},
	{
		"name": "Stephanie Lampkin",
		"company": "Blendoor",
		"role": "Founder & CEO",
		"img": `${StephanieLampkin}`
  	},
	{
		"name": "Tabreez Verjee",
		"company": "Uprising",
		"role": "Co-founder & Partner",
		"img": `${TabreezVerjee}`
  	},
	{
		"name": "Tim Chang",
		"company": "Mayfield",
		"role": "Partner",
		"img": `${TimChang}`
  	},
	{
		"name": "Tina Sharkey",
		"company": "Founder, Investor, Advisor, Builder",
		"role": "",
		"img": `${TinaSharkey}`
	  },
	 
	{
		"name": "Trevor Martin",
		"company": "Mammoth",
		"role": "Co-founder & CEO",
		"img": `${TrevorMartin}`
	  },
	{
		"name": "Kristin McDonald",
		"company": "Eniac Ventures",
		"role": "Investor",
		"img": `${KristinMcDonald}`
  	},
	{
		"name": "Tim Young",
		"company": "Eniac Ventures",
		"role": "Founding General Partner",
		"img": `${TimYoung}`
  	},
	{
		"name": "Vic Singh",
		"company": "Eniac Ventures",
		"role": "Founding General Partner",
		"img": `${VicSingh}`
  	},
	{
		"name": "Anna Nitschke",
		"company": "Eniac Ventures",
		"role": "VP, Finance & Operations",
		"img": `${AnnaNitschke}`
  	},
	{
		"name": "Hadley Harris",
		"company": "Eniac Ventures",
		"role": "Founding General Partner",
		"img": `${HadleyHarris}`
  	},
	{
		"name": "Kamil Saeid",
		"company": "Ridge Ventures",
		"role": "Principal",
		"img": `${KamilSaeid}`
  	},
	{
		"name": "Alex Shah",
		"company": "The London Fund",
		"role": "Founder",
		"img": `${AlexShah}`
  	},
	{
		"name": "Anjula Acharia",
		"company": "Trinity Ventures",
		"role": "Manager, Priyanka Chopra",
		"img": `${AnjulaAcharia}`
  	},
	{
		"name": "Bharat Vasan",
		"company": "The Production Board",
		"role": "President & COO",
		"img": `${BharatVasan}`
  	},
	{
		"name": "Chantal Kreviazuk",
		"company": "",
		"role": "Musician",
		"img": `${ChantalKreviazuk}`
  	},
	{
		"name": "Chirag Chotalia",
		"company": "Threshold VC",
		"role": "Partner",
		"img": `${ChiragChotalia}`
  	},
	{
		"name": "Christina Lewis",
		"company": "All Star Code",
		"role": "Founder & President",
		"img": `${ChristinaLewis}`
  	},
	{
		"name": "Damali Taylor",
		"company": "O'Melveny",
		"role": "Partner",
		"img": `${DamaliTaylor}`
  	},
	{
		"name": "Dan Rosen",
		"company": "Commerce Ventures",
		"role": "Founder & General Partner",
		"img": `${DanRosen}`
  	},
	{
		"name": "Darnell Smith",
		"company": "Mojo",
		"role": "CEO",
		"img": `${DarnellSmith}`
  	},
	{
		"name": "Denmark West",
		"company": "Connectivity Ventures",
		"role": "Founding Partner",
		"img": `${DenmarkWest}`
  	},
	{
		"name": "Dule Hill",
		"company": "Actor",
		"role": "Actor",
		"img": `${DuleHill}`
  	},
	{
		"name": "Eve Blossom",
		"company": "Material Change Institute",
		"role": "Associate",
		"img": `${EveBlossom}`
  	},
	{
		"name": "Genil Washington",
		"company": "Stanford Health Care",
		"role": "Director Talent Acquisition",
		"img": `${GenilWashington}`
  	},
	{
		"name": "Hari Menon",
		"company": "Paanini",
		"role": "Co-founder @ Group President",
		"img": `${HariMenon}`
  	},
	{
		"name": "Ila Afsharipour",
		"company": "KeyBanc Capital Markets",
		"role": "Managing Director",
		"img": `${IlaAfsharipour}`
  	},
	{
		"name": "Javaughn Lawrence",
		"company": "Eniac Ventures",
		"role": "Entrepreneur In Residence",
		"img": `${JavaughnLawrence}`
  	},
	{
		"name": "Kai Bond",
		"company": "CourtsideVC",
		"role": "Partner",
		"img": `${KaiBond}`
  	},
	{
		"name": "Kamesh Nagarajan",
		"company": "Morgan Stanley",
		"role": "Executive Director Financial Advisor",
		"img": `${KameshNagarajan}`
  	},
	{
		"name": "Kevin Hawkins",
		"company": "Booking Holdings",
		"role": "User Experience",
		"img": `${KevinHawkins}`
  	},
	{
		"name": "Kunal Modi",
		"company": "McKinsey & Company",
		"role": "Associate Partner",
		"img": `${KunalModi}`
  	},
	{
		"name": "Manesh Patel",
		"company": "Szul",
		"role": "Co-founder & COO",
		"img": `${ManeshPatel}`
  	},
	{
		"name": "Marta Bulaich",
		"company": "VSC",
		"role": "Head of Marketing",
		"img": `${MartaBulaich}`
  	},
	{
		"name": "Michael Katz",
		"company": "mParticle",
		"role": "CEO & Co-founder",
		"img": `${MichaelKatz}`
  	},
	{
		"name": "Neera Chanani",
		"company": "Chanani Group",
		"role": "Managing Partner",
		"img": `${NeeraChanani}`
  	},
	{
		"name": "Nnamdi Okike",
		"company": "645 ventures",
		"role": "Co-founder & Managing Partner",
		"img": `${NnamdiOkike}`
  	},
	{
		"name": "Paige Craig",
		"company": "Outlander Labs",
		"role": "Partner",
		"img": `${PaigeCraig}`
  	},
	{
		"name": "Raine Maida",
		"company": "Our Lady Peace",
		"role": "Musician",
		"img": `${RaineMaida}`
  	},
	{
		"name": "Raj Amin",
		"company": "Teem Ventures",
		"role": "Founder & Managing Partner",
		"img": `${RajAmin}`
  	},
	{
		"name": "Rick Marini",
		"company": "Grindr",
		"role": "Chief Operating Officer",
		"img": `${RickMarini}`
  	},
	{
		"name": "Rob Oshima",
		"company": "Rally",
		"role": "VP of Business Development/Partnerships",
		"img": `${RobOshima}`
  	},
	{
		"name": "Sean Green",
		"company": "Arternal",
		"role": "Co-founder & CEO",
		"img": `${SeanGreen}`
  	},
	{
		"name": "Shai Goldman",
		"company": "Silicon Valley Bank",
		"role": "Managing Director",
		"img": `${ShaiGoldman}`
  	},
	{
		"name": "Sid Shah",
		"company": "BCG Digital Ventures",
		"role": "Managing Director & Partner",
		"img": `${SidShah}`
  	},
	{
		"name": "Suresh Bhat",
		"company": "Vouchr",
		"role": "Co-founder & COO",
		"img": `${SureshBhat}`
  	},
	{
		"name": "Tom Chavez",
		"company": "Super{set}",
		"role": "Co-founder & General Partner",
		"img": `${TomChavez}`
	  },

	{
		"name": "Ty Stiklorius",
		"company": "Friends At Work",
		"role": "Founder & CEO",
		"img": `${TyStiklorius}`
	  },
	  {
		"name": "Matt Uffalussy",
		"company": "Gladio",
		"role": "Founder & CEO",
		"img": `${MattUffalussy}`
	  },
	  {
		"name": "Kayla Abbassi",
		"company": "VSC",
		"role": "Sr. Account Manager",
		"img": `${KaylaAbbassi}`
	  }	  
	  
]

export default headshotData