import React from 'react'
import styled from 'styled-components'
import {useHistory, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import isEmpty from 'lodash.isempty'
import {useAuth0} from '@auth0/auth0-react'
import {LinkedinShareButton, FacebookShareButton} from 'react-share'

import {Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import TwitterIcon from '@material-ui/icons/Twitter'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import FacebookIcon from '@material-ui/icons/Facebook'

import PledgeCard from '../PledgeCard'
import {DefaultButton as ActionButton} from '../../components/Buttons'

import {composeSocialMediaMessage} from '../../utils'

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
  flex-direction:column;

  @media (min-width: 768px){
    flex-direction:row;
  }
  ${({theme}) => `
  button, a {
    flex-grow: 0;
    height:50px;
    margin:10px 0;


    @media (min-width: 768px){
      flex-grow: 1;
      margin-right: 2.4rem;
      &:last-child{
        margin-right: 0;
      }
    }
  }
`}
`
const Header = styled(Typography)`
  text-align: center;
  margin-bottom: 2rem;
`

const PledgeDisclaimer = styled(Typography)`
  text-align: center;
  margin-bottom: 2.4rem;
`
const PledgeSuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
`
const TwitterShare = styled.a`
  text-decoration: none;
  min-width: 16.8rem;
  display: flex;
  align-items: center;
  background-color: #49a1ec;
  justify-content: center;
  border-radius: 6px;
  height: 46px;
  svg {
    margin-right: 10px;
  }
`
const LinkedInShare = styled(LinkedinShareButton)`
  text-decoration: none;
  min-width: 16.8rem;
  background-color: #2d72ad !important;
  border-radius: 0.6rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 10px;
  }
`

const FacebookShare = styled(FacebookShareButton)`
  text-decoration: none;
  min-width: 16.8rem;
  background-color: #4267B2 !important;
  border-radius: 0.6rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 10px;
  }
`
const useStyles = makeStyles((theme) => ({
  label: {
    letterSpacing: '0.05em',
    fontSize: '1.4rem',
  },
}))

const PledgeSuccess = ({pledge, userInfo}) => {
  const classes = useStyles()
  const history = useHistory()
  const {isAuthenticated} = useAuth0()

  const navigateHome = () => history.push('/')
  
  return !isEmpty(pledge) ? (
    <PledgeSuccessContainer>
      <Header variant="h3" color="primary">
        Thank you for your pledge!
      </Header>
      <PledgeCard pledge={pledge} />
      <Typography variant="body1" color="primary">
      <p>As the 100kPledge community continues to grow, additional features will help you track your progress, send personalized reminders, and provide resources to guide your donation, hiring, and investment initiatives.</p>
      <p>Meanwhile, we need your help to build this movement. Invite your friends, family, and colleagues to make their pledge using the sharing links below.</p>
      <p>(Copy and paste your pledge into LinkedIn below to share.) </p>
      </Typography>
      {!isAuthenticated ? (
        <PledgeDisclaimer variant="subtitle2" color="primary">
          Your pledge is pending approval before it can be published on the
          pledge feed. You will receive an email once it has been approved.
        </PledgeDisclaimer>
      ) : (
        ''
      )}
      {isAuthenticated ? (
        <ButtonGroup>
          <LinkedInShare 
           url={`${process.env.REACT_APP_100K_DOMAIN}/pledge/${pledge.id}`}
          >
            <LinkedInIcon color="primary" />
            <Typography
              variant="button"
              color="primary"
              classes={{root: classes.label}}>
              LinkedIn
            </Typography>
          </LinkedInShare>
          <FacebookShare
            url={`${process.env.REACT_APP_100K_DOMAIN}/pledge/${pledge.id}`}
            quote={`${composeSocialMediaMessage(
              pledge,
              userInfo,
              false
            )}`}
            >
              <FacebookIcon color="primary" />
              <Typography
                variant="button"
                color="primary"
                classes={{root: classes.label}}>
              Facebook
            </Typography>
          </FacebookShare>
          <TwitterShare
            href={`https://twitter.com/intent/tweet?text=${composeSocialMediaMessage(
              pledge,
              userInfo,
            )}`}
            rel="me">
            <TwitterIcon color="primary" />
            <Typography
              variant="button"
              color="primary"
              classes={{root: classes.label}}>
              Twitter
            </Typography>
          </TwitterShare>
        </ButtonGroup>
      ) : (
        ''
      )}
      <ActionButton
        customStyles={{width: '100%', marginTop: '2rem'}}
        onClick={navigateHome}
        type="primary">
        Return Home
      </ActionButton>
    </PledgeSuccessContainer>
  ) : (
    <Redirect to="/" />
  )
}
const mapStateToProps = ({user: {userInfo}, pledges: {pledge}}) => {
  return {
    userInfo,
    pledge,
  }
}

const ConnectedPledgeSuccess = connect(mapStateToProps)(PledgeSuccess)
export default ConnectedPledgeSuccess
