import currency from 'currency.js'
import orderBy from 'lodash.orderby'
import {PLEDGE_MESSAGE_MAP} from './constants'

const formatCurrency = (value, hasCurrencySymbol = false) =>
  currency(value)
    .format(hasCurrencySymbol)
    .replace(/(\.(\d){2})$/, '')

const composeSocialMediaMessage = (
  {pledgeAmount, name, email, id, pledgeMessage},
  {email: userEmail = ''},
  encode
) => {
  const formattedCurrency = formatCurrency(pledgeAmount, true)
  const domain = process.env.REACT_APP_100K_DOMAIN
  const pledgeLink = encode?`${domain}%2Fpledge%2F${id}`:`${domain}/pledge/${id}`
  const br = encode?'%0A':'\n'
  return userEmail === email
    ? composeSelfPledgeMessage({pledgeMessage, name, formattedCurrency, id, pledgeLink, br, domain})
    : composeOtherPledgeMessage({name, formattedCurrency, id, pledgeLink, br, domain})
}
const composeSelfPledgeMessage = ({pledgeMessage, name, formattedCurrency, id, pledgeLink, br, domain}) =>
  `Join me to commit to the economic empowerment of Black communities. I'm pledging ${formattedCurrency} through career opportunities, investments and donations by 2030. ${pledgeLink}${br}${br}@the100kpledge`

const composeOtherPledgeMessage = ({name, formattedCurrency, id, pledgeLink, br, domain}) =>
  `Join ${name} to commit to the economic empowerment of Black communities. ${name.split(' ')[0]} is pledging ${formattedCurrency} through career opportunities, investments and donations by 2030. ${pledgeLink}${br}${br}@the100kpledge`

const composePledgeMessage = ({pledgeType, pledgeAmount}) => {
  const formattedCurrency = formatCurrency(pledgeAmount, true)
  const pledgeCategories = composePledgeTypeDetails(
    pledgeType,
    PLEDGE_MESSAGE_MAP,
  )
  return `Over the next 10 years, I’m committing ${formattedCurrency} to economic empowerment of Black communities through ${pledgeCategories}.`
}

const composePledgeTypeDetails = (pledgeType, PLEDGE_MESSAGE_MAP) => {
  if (pledgeType.length === 1)
    return `${PLEDGE_MESSAGE_MAP[pledgeType].category}`
  else {
    return pledgeType.reduce((acc, type, i) => {
      return i === pledgeType.length - 1
        ? acc + `and ${PLEDGE_MESSAGE_MAP[type].category}`
        : acc + `${PLEDGE_MESSAGE_MAP[type].category}, `
    }, '')
  }
}

const sortPledge = (pledges, sortType) => {
  return orderBy(pledges, [sortType, 'name'], ['desc', 'asc'])
}

const getPledgeTotal = (pledges) => {
  return pledges.reduce((acc, pledge) => {
    return acc + pledge.pledgeAmount
  }, 0)
}
export {
  formatCurrency,
  composeSocialMediaMessage,
  composePledgeMessage,
  sortPledge,
  getPledgeTotal,
}
