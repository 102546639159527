import {
  ADD_PLEDGE_SUCCESS,
  GET_PLEDGES_SUCCESS,
  SORT_PLEDGE,
  GET_USER_PLEDGE,
  UPDATE_PLEDGES,
} from '../actions/types'

import {sortPledge, getPledgeTotal} from '../../utils'

const pledges = (state = {}, action = {}) => {
  
  switch (action.type) {
    case ADD_PLEDGE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        pledge: action.pledge,
      })
    case GET_PLEDGES_SUCCESS:
      const pledgeTotalAmount = getPledgeTotal(action.pledges)
      const pledgeCount = action.pledges.length
      const sortedPledgeList = sortPledge(action.pledges, state.pledgeFilter)
      const pledgesLoaded = true
      return Object.assign({}, state, {
        pledges: [...action.pledges],
        pledgeList: [...sortedPledgeList],
        pledgeTotalAmount,
        pledgeCount,
        pledgesLoaded,
      })
    case SORT_PLEDGE:
      const filteredPledge = sortPledge(state.pledges, action.sortType)
      return Object.assign({}, state, {
        pledgeFilter: action.sortType,
        pledgeList: filteredPledge,
      })
    case UPDATE_PLEDGES:
      return Object.assign({}, state, {
        pledges: action.pledges,
      })

    case GET_USER_PLEDGE:
      const userPledge = state.pledges.filter(
        (pledge) => pledge.email === action.email,
      )
      return Object.assign({}, state, {
        pledgeList: userPledge,
      })
    default:
      return state
  }
}

export default pledges
