import React from 'react'

import PledgeCard from './PledgeCard'
import PledgeFilter from './PledgeFilter'
import {SORT_OPTIONS} from '../utils/constants'
const PledgesContainer = ({pledges = []}) => {
  return (
    <>
      <PledgeFilter filter={SORT_OPTIONS} />
      {pledges.map((info, i) => (
        <PledgeCard key={info.id} pledge={info} hasSocialMedia />
      ))}
    </>
  )
}

export default PledgesContainer
