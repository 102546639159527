import React, {useEffect , useState }  from 'react'
import styled from 'styled-components'
import {connect} from 'react-redux'
import {useAuth0} from '@auth0/auth0-react'
import isEmpty from 'lodash.isempty'
import Typography from '@material-ui/core/Typography'
import {makeStyles} from '@material-ui/core/styles'
import Obfuscate from 'react-obfuscate';

import {DefaultButton as ActionButton} from '../components/Buttons'
import {DarkCard} from '../components/Cards'
import Headshots from '../components/Headshots'
import Logo from '../components/Logo'

import Pledges from './Pledges'

import {getPledges, likePledge, updateUser} from '../redux/actions'
import {formatCurrency} from '../utils'
import AnchorLink from 'react-anchor-link-smooth-scroll'

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2.4rem;

  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index:100;
  padding:5px 0;
  background-color:#0F101A;

  ${({theme}) => `
    button {
      flex-grow: 1;
  `}
`

const PledgeCard = styled(DarkCard)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  padding: 1.2rem 0;
`

const useStyles = makeStyles((theme) => ({
  body: {
    marginBottom: '5px',
  },
  pledgeTextAccent: {
    color: theme.colors.secondaryLight,
  },
}))

const StyledUnderline = styled.span`
  ${({theme}) => `
    border-bottom: 2px solid ${theme.colors.secondaryLight};
    > span {
      color:  ${theme.colors.secondaryLight};
    }
  `}
`
const FounderHeadline = styled.div`
  ${({theme}) => `
  display: flex;
  justify-content: center;
  margin: 30px 0;
  letter-spacing: -0.5px;
  @media (${theme.screenSizes.sm}) { 
    letter-spacing: 0.5px;
  };
  @media (${theme.screenSizes.md}) { 
    letter-spacing: 1px;
  };
`}
`

const Home = ({
  pledges,
  pledgeList,
  pledgeTotalAmount = 0,
  pledgesLoaded = false,
  pledgeCount = 0,
  dispatchGetPledges,
  dispatchLikePledge,
  navigateToPledge,
  dispatchUpdateUser,
}) => {
  const classes = useStyles()
  const {isAuthenticated, user} = useAuth0()
  useEffect(() => {
    isEmpty(pledges) && dispatchGetPledges()
  }, [dispatchGetPledges, pledges])

  useEffect(() => {
    isAuthenticated && dispatchUpdateUser(user)
  }, [dispatchUpdateUser, isAuthenticated, user])

  const formattedPledgeTotal =
    formatCurrency(pledgeTotalAmount, true) || pledgeTotalAmount

  const [homeIsReady, setHomeIsReady] = useState(false);
  setTimeout(() => setHomeIsReady(true), 0)
  return (
    <>
    <div 
      className={`${!homeIsReady ? "waiting" : "loaded"}`}
      >
      <Logo/>
      <Typography variant="body1" color="primary">
        The 100kPledge is a public social contract and tracker for professionals and organizations committing to Black communities' economic empowerment through hiring, investment, and donations.
        <br /> <br />
        The tracker serves as a starting point. We record commitments over $100,000 made publicly and through the 100kPledge to encourage accountability across the board.
        <br /> <br />
        As the community grows, the 100kPledge will expand features to track progress and help guide direct action.
        <br /> <br />
        Join us by taking the pledge below. For any questions, reach out to <Obfuscate email="info@the100kpledge.com"/>.
      </Typography>
      <ButtonGroup>
        <ActionButton onClick={navigateToPledge} type="primary">
          make your pledge
        </ActionButton>
      </ButtonGroup>
    
      <PledgeCard
        className={`${!pledgesLoaded ? "waiting" : "loaded"}`}
      >
        <Typography
          className={classes.body}
          variant="subtitle1"
          color="primary">
          <AnchorLink href='#pledges' offset='80'>
            <span>{pledgeCount}</span> pledges
          </AnchorLink>{' '}
          totaling
        </Typography>
        <Typography className={classes.pledgeTextAccent} variant="h3">
          {formattedPledgeTotal}
        </Typography>
      </PledgeCard>
      <FounderHeadline>
        <Typography variant="h4" color="primary">
          MEET THE 130+ FOUNDING MEMBERS
        </Typography>
      </FounderHeadline>
      <Headshots/>
      <section id='pledges'>
        <Pledges pledges={pledgeList} />
      </section>
      </div>
    </>
  )
}

const mapStateToProps = ({
  user: {userInfo},
  pledges: {pledgeList = [], pledges, pledgeTotalAmount, pledgeCount, pledgesLoaded},
}) => {
  return {
    userInfo,
    pledgeList,
    pledges,
    pledgeTotalAmount,
    pledgeCount,
    pledgesLoaded
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchGetPledges: () => {
      dispatch(getPledges())
    },
    dispatchUpdateUser: (user) => {
      dispatch(updateUser(user))
    },
    dispatchLikePledge: () => {
      dispatch(likePledge())
    },
  }
}
const ConnectedHome = connect(mapStateToProps, mapDispatchToProps)(Home)
export default ConnectedHome
